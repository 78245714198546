/**
 * Created by Maxim B. on 23/07/20.
 * Copyright © 2020 SEVEN. All rights reserved.
 */
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { LearningObjective } from '../../api-subject-details';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { StudentNotesService } from './student-notes.service';
import { map } from 'rxjs/operators';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { fadeAnimation } from '../../../../shared/animations/fade.animation';
import { FileUploadService } from '../../../../shared/components/edit-question-block/file-upload.service';
import { Observable } from 'rxjs';
import { forkJoin } from 'rxjs';

interface TeacherStudentNote {
  title?: string;
  note?: string;
  curriculumLearningObjectiveId?: number | string;
  id?: number;
}

@Component({
  selector: 'curr-teacher-student-notes',
  templateUrl: './teacher-student-notes.component.html',
  styleUrls: ['./teacher-student-notes.component.scss'],
  animations: [fadeAnimation]
})
export class TeacherStudentNotesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() topic: LearningObjective;

  inEditMode = false;
  isNotesLoading = false;
  isNotesFormVisible = false;
  notes: TeacherStudentNote[] = [];
  newNote: TeacherStudentNote = { title: '', note: '' };
  now = Date.now();
  updateNotessubscription: Subscription;

  constructor(private toastr: ToastrService,
              public dialog: MatDialog,
              private notesService: StudentNotesService,
              private fileUploadService: FileUploadService) {
  }

  get isEditFieldsValid(): boolean {
    return this.notes?.some(note => !note.title);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.topic) {
      if (changes?.topic?.currentValue?.id !== changes?.topic?.previousValue?.id) {
        this.loadNotesForCurrTopic();
      }
    }
  }

  // TODO: remove?
  ngOnInit(): void {
  }

  loadNotesForCurrTopic() {
    if (!this.topic?.id) {
      return;
    }
    this.isNotesLoading = true;
    this.notesService
      .getNotes(+this.topic.id)
      .pipe(
        map(r => {
          return (r as any).data.items;
        })
      )
      .subscribe(resp => {
        this.notes = resp.map(note => {
          return { ...note, curriculumLearningObjectiveId: this.topic.id };
        });

        for (const note of this.notes) {
          if (note.note.indexOf('<img src')) {
            // this.uploadImages(note);
          }
        }
        setTimeout(() => (this.isNotesLoading = false), 1000);
      }, this.onError);
  }

  uploadImages(note) {
    const imageKeyValue = this.fileUploadService.getAllImageKeysFromNote(note.note);
    this.isNotesLoading = true;
    let noteText = note.note;

    const observersObj: { [key: string]: Observable<any> } = {};

    for (const key of Object.keys(imageKeyValue)) {
      observersObj[key] = this.fileUploadService.download(key, 'IMAGE');
    }

    forkJoin(observersObj).subscribe({
      next: (res: any) => {
        for (const [key, value] of Object.entries(res)) {
          const bytes = (value as any)?.data?.bytes;
          imageKeyValue[key] = 'data:image/png;base64,' + bytes;
          noteText = noteText.replace(key, imageKeyValue[key]);
        }

        note.note = noteText;
        this.isNotesLoading = false;
      },
      error: () => (this.isNotesLoading = false)
    });
  }

  saveNote() {
    this.newNote.curriculumLearningObjectiveId = this.topic.id;
    this.notesService.saveNote(this.newNote).subscribe(resp => {
      const note = { ...(resp as any).data, curriculumLearningObjectiveId: this.topic.id };
      this.notes.push(note);
      this.isNotesFormVisible = false;
      this.toastr.success('Student note created!');
      this.newNote = { title: '', note: '', id: undefined };
    }, this.onError);
  }

  updateNote(note: TeacherStudentNote) {
    if (note.curriculumLearningObjectiveId !== this.topic.id) {
      return;
    }
    if (!note.curriculumLearningObjectiveId) {
      note.curriculumLearningObjectiveId = this.topic.id;
    }
    this.notesService
      .updateNote(note)
      // .pipe(delay(1000))
      .subscribe(resp => {
        this.toastr.info('Student note updated!');
      }, this.onError);
  }

  deleteNote(i: number) {
    this.notesService.deleteNote(this.notes[i].id).subscribe(() => {
      this.notes.splice(i, 1);
      this.toastr.warning('Student note was deleted!');
    }, this.onError);
  }

  editNotes() {
    if (this.inEditMode) {
      this.updateNotessubscription = from(this.notes)
        .pipe(mergeMap(note => this.notesService.updateNote(note)))
        .subscribe(resp => {
          this.toastr.info('Student note updated!');
        }, this.onError);
    }
    this.inEditMode = !this.inEditMode;
  }

  ngOnDestroy() {
    this.updateNotessubscription?.unsubscribe();
  }

  onError(err) {
    this.toastr.warning('Error happened: ' + err);
  }
}
