<!-- Created by Daniil V. on 15/02/21.-->
<!-- Copyright © 2021 SEVEN. All rights reserved.-->
<div class="full-width m-t-16 nav-container-sow">

  <div fxLayout="row" fxLayoutAlign="space-between center" id="nav-sow" class="">
    <div class="overline last-modified ">Last modified: {{ topic?.updated_at | date }}</div>
    <curr-button *ngIf="!isEditingMode" color="primary" variant="outlined" (btnClick)="togglEditingNotes()">EDIT
    </curr-button>
    <curr-button *ngIf="isEditingMode" [isDisabled]="!isEditFieldsValid" color="primary" variant="outlined"
      (btnClick)="updateNotes()">
      SAVE
    </curr-button>
  </div>
</div>

<div class="m-t-16">
  <curr-loader [diameter]="40" [isLoading]="isNotesLoading" *ngIf="isNotesLoading"></curr-loader>
</div>

<div @fade class="m-t-16 full-width" *ngIf="!isNotesLoading">
  <div fxLayout="row" fxLayoutAlign="center center" *ngIf="!notes.length && !isAddingMode">
    <span class="mat-body-1 m-b-16">No content</span>
  </div>

  <ng-container *ngFor="let note of notes; let i = index">
    <div fxLayout="column" fxLayoutAlign="start stretch" class="m-t-16">
      <div class="title mat-body-2 m-b-16">
        <curr-input *ngIf="isEditingMode" [isRequired]="true" [(value)]="note.title" fxFlex="70"
          placeholder="Enter note title" errorMessage="Title can't be empty">
        </curr-input>
        <ng-container *ngIf="!isEditingMode">{{ note.title }}:</ng-container>
      </div>

      <div [id]="'note-'+note.id" fxLayout="row" fxLayoutAlign="space-between center" class="delete-column-container">
        <curr-tiny-editor-disabled [topic]="topic" [(value)]="note.note" [disabled]="!isEditingMode"
          [options]="{ height: 500, placeholder: '', menubar: 'file edit view  insert format table help' }"
          fxFlex="100"></curr-tiny-editor-disabled>

        <button mat-icon-button (click)="deleteNote(note.id)" *ngIf="isEditingMode">
          <mat-icon [svgIcon]="'trash'"></mat-icon>
        </button>
      </div>
    </div>
  </ng-container>

  <div class="m-b-8 m-t-16" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="isAddingMode">
    <curr-input placeholder="Enter note title" [(value)]="newNote.title" fxFlex="70" class="no-hint-input"></curr-input>
    <curr-button (btnClick)="addNote()" [isDisabled]="!newNote.title" [variant]="'contained'">
      SAVE
    </curr-button>
    <curr-button (btnClick)="toggleAddingNote()" variant="text">CANCEL</curr-button>
  </div>

  <div class="m-b-8 m-t-16" fxLayout="row" fxLayoutAlign="space-between center">
    <curr-button [icon]="'plus'" [variant]="'contained'" (btnClick)="toggleAddingNote()">
      ADD TEACHER GUIDELINES
    </curr-button>
  </div>
</div>