<!--
  ~ Created by Yaroslav S. on 16/04/24.
  ~ Copyright © 2024 SEVEN. All rights reserved.
-->

<div fxLayout="row" fxLayoutAlign="space-between start" class="header m-b-24">
  <div class="confirm-title" fxFlex>{{dialogTitle}}</div>
</div>
<div fxLayout="column" fxLayoutAlign="center center">
  <div class="confirm-content" *ngIf="showContent">
    <mat-icon icon="error"></mat-icon>
    <div class="confirm-content-text">Please note: Any progress made on the current question will be lost. Are you sure you want to proceed?</div>
  </div>
  <div class="full-width" fxLayout="row" fxLayoutAlign="end center">
    <curr-button variant="text" (btnClick)="dialogRef.close()" size="big">CANCEL</curr-button>
    <curr-button variant="contained" class="m-l-16" (btnClick)="confirm()" size="big">CONFIRM</curr-button>
  </div>
</div>
