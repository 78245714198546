/**
 * Created by Maxim Bon. on 13/05/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CumulativeTest } from '../cumulative-test';
import { Observable, timer } from 'rxjs';
import { take } from 'rxjs/operators';
import { RestApiService, restApiServiceCreator } from '../../../shared/api/rest-api.service';
import { ApplicationHttpClient } from '../../../shared/api/application-http-client';
import { Router } from '@angular/router';
import { TestCreationService } from '../test-creation.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

@Component({
  selector: 'curr-save-test-popup',
  templateUrl: './save-test-popup.component.html',
  styleUrls: ['./save-test-popup.component.scss']
})
export class SaveTestPopupComponent implements OnInit {
  cTestService: RestApiService<CumulativeTest>;

  test: CumulativeTest;
  isTestSaved = false;
  testInSavingProgress = false;
  saveVariant = '';
  userCanDownload = [];
  selectedHours = 24;
  d: Date;
  fromDate: Date;
  toDate: Date;

  constructor(
    public dialogRef: MatDialogRef<SaveTestPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private tcService: TestCreationService,
    private toastr: ToastrService,
    private router: Router,
    private httpClient: ApplicationHttpClient
  ) {
  }

  ngOnInit(): void {
    this.cTestService = restApiServiceCreator<CumulativeTest>('curriculumCumTests')(this.httpClient);
    this.test = this.data.test;
    console.log('testData dialog', this.test)
    // this.d = new Date(this.test.start_time);
    this.fromDate = new Date(this.test.start_time);
    this.toDate = new Date(this.test.end_time);
  }

  setSelectedHours($event: any | []) {
    this.selectedHours = $event;
  }

  saveTest(variant: string) {
    // this.test.start_time =
    //   `${ this.d.getFullYear() }-${ (this.d.getMonth() + 1).toString().padStart(2, '0') }-${ (this.d.getDate()).toString().padStart(2, '0') } ${ this.d.getHours() }:${ this.d.getMinutes() }:${ this.d.getSeconds() }`;
    if (variant === 'draft') {
      this.test.is_published = false;
    } else {
      this.test.is_published = true;
    }
    if (this.test.is_offline) {
      this.saveOffLine();
      return;
    }
    this.testInSavingProgress = true;
    const testId = this.data.testId;
    let req: Observable<CumulativeTest>;
    if (testId === 'new') {
      req = this.tcService.saveCumulativeTest(this.test);
    } else {
      req = this.tcService.updateCumulativeTest(this.test);
    }

    req.subscribe(resp => {
      const r = resp as any;
      if (testId !== 'new' && r.data) {
        this.isTestSaved = true;
        this.saveVariant = variant;
        this.toastr.success('Test saved!');
      } else if (r.id) {
        this.isTestSaved = true;
        this.saveVariant = variant;
        this.test = r;
        this.toastr.info('Test updated!');
        timer(1000).pipe(take(1)).subscribe(() => {
          this.dialogRef.close(this.test);
          this.router.navigate(['courses', this.data.testTypeParam, this.test.school_year_course_id, this.test.curriculum_paper_id]);
        });
      }
    }, (err) => {
      this.toastr.warning('An error happened');
    }, () => {
      this.testInSavingProgress = false;
      this.dialogRef.close(this.test);
    });

  }

  saveOffLine() {
    const testId = this.data.testId;
    let req: Observable<CumulativeTest>;
    if (testId === 'new') {
      req = this.tcService.saveOfflineCumulativeTest(this.test);
    }
    req.subscribe(resp => {
      const r = resp as any;
      if (testId === 'new' && r.data) {
        this.isTestSaved = true;
        this.toastr.success('Test saved!');
      } else if (r.data.id) {
        this.isTestSaved = true;
        this.test = r.data;
        this.toastr.info('Test updated!');
        timer(1000).pipe(take(1)).subscribe(() => {
          this.dialogRef.close(this.test);
          this.router.navigate(['courses', this.data.testTypeParam, this.test.school_year_course_id, this.test.curriculum_paper_id]);
        });
      }
    }, (err) => {
      this.toastr.warning('An error happened');
    }, () => {
      this.testInSavingProgress = false;
      this.dialogRef.close(this.test);
    });
  }

}
