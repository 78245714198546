<!-- Created by Alex Poh. on 05/04/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<!-- Paper info extansion panel -->
<curr-paper-info
  [paper]="paper"
  [sowDescriptions]="subject.sowDescriptions"
  (editPaperName)="editPaperName()"
  (addAboutItem)="addAboutPaper()"
  (editAboutItem)="editAboutPaper($event)"
  (deleteAbout)="onDeleteAbout($event)"
  (openAiAssistant)="openAiAssistant()"
>
</curr-paper-info>

<div class="curr-paper-details">
  <div class="sow-topics-list">
    <div class="sow-title-section">
      <div class="sow-title">Topics</div>
      <mat-icon svgIcon="plus" [ngClass]="!masterQuestions ? 'disabled' : ''" (click)="addTopic()"></mat-icon>
    </div>
    <curr-sow-topics-list
      *ngIf="paperResponse.learningObjectives.length > 0"
      [topics]="paperResponse.learningObjectives"
      (onChengeTopicsOrder)="changeTopicsOrder($event)"
      (onSelectTopicItem)="selectTopic($event)"
      (onEditTopicItem)="editTopicItem($event)"
      (onDeleteTopicItem)="deleteTopicItem($event)"
    >
    </curr-sow-topics-list>
    <div class="no-topics" *ngIf="paperResponse.learningObjectives.length === 0">Please, Add Topics Here</div>
  </div>

  <div class="tabs-list">
    <mat-tab-group
      *ngIf="selectedTopic"
      [dynamicHeight]="true"
      mat-align-tabs="center"
      [(selectedIndex)]="selectedMenuIndex"
      animationDuration="0ms"
      class="nav-panel tab-element custom-ink-size"
    >
      <mat-tab label="SCHEME OF WORK">
        <ng-template matTabContent>
          <div class="tab-content">
            <curr-scheme-of-work
              *ngIf="fetchedLearningObjective"
              [lo]="fetchedLearningObjective"
              [subject]="subject"
              [secNumPrefix]="secNumPrefix"
              [paperResponse]="paperResponse"
              (topicUpdated)="onTopicUpdate($event)"
              (newEntityWasAdded)="addLearningObjective($event)"
            >
            </curr-scheme-of-work>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab label="MY QUESTION BANK">
        <ng-template matTabContent>
          <div class="tab-content questions-tab">
            <curr-my-question-bank
              [curriculumId]="subject.paper.curriculum_id"
              [learningObjective]="fetchedLearningObjective"
              [examinationBoard]="examinationBoardList"
            >
            </curr-my-question-bank>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab label="TEACHER GUIDANCE">
        <ng-template matTabContent>
          <div class="tab-content">
            <curr-teacher-guidance-notes [topic]="fetchedLearningObjective"> </curr-teacher-guidance-notes>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab label="STUDENT HANDOUT">
        <ng-template matTabContent>
          <div class="tab-content">
            <curr-teacher-student-notes [topic]="fetchedLearningObjective"></curr-teacher-student-notes>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab label="LEARNING RESOURCES">
        <ng-template matTabContent>
          <div class="tab-content">
            <curr-learning-resources
              [learningObjective]="fetchedLearningObjective"
              (learningObjectiveUpdated)="onTopicUpdate($event)"
            >
            </curr-learning-resources>
          </div>
        </ng-template>
      </mat-tab>
      <!-- <mat-tab label="LESSON PLAN">
        <div class="tab-content">
          <ng-template matTabContent>
            <curr-lesson-plan [learningObjective]="fetchedLearningObjective"
              (learningObjectiveChanged)="selectLearningObjective($event)"
              [learningObjectives]="paperResponse.learningObjectives"></curr-lesson-plan>
          </ng-template>
        </div>
      </mat-tab> -->
      <!-- <mat-tab label="COU">
        <div class="tab-content">
          <ng-template matTabContent>
            <curr-cou [topic]="fetchedLearningObjective" [subjectDetailsId]="curriculumId"
              (topicUpdated)="onTopicUpdate($event)" (emitQuestions)="getQuestions($event)"></curr-cou>
          </ng-template>
        </div>
      </mat-tab> -->
    </mat-tab-group>

    <div *ngIf="!selectedTopic" class="no-selected-topic">
      <img src="/assets/images/colored/empty-task-list.svg" />
      <div class="empty-text">Please, Choose Topic</div>
    </div>
  </div>
</div>
