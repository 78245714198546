/*
 * Created by Dmytro Sav. on 4/28/2020
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, Input } from '@angular/core';
import { QuestionType } from '../../../../student/test.model';

@Component({
  selector: 'curr-students-list-question',
  templateUrl: './students-list-question.component.html',
  styleUrls: ['./students-list-question.component.scss']
})
export class StudentsListQuestionComponent {

  isResultImage: boolean = false;
  qt = QuestionType;

  @Input() question: any;
  @Input() isFirst = false;

  constructor() {}

  checkHasNotCorrectSelfMark(selfMarkSchemas: any) {
    if (selfMarkSchemas) return selfMarkSchemas.filter(item => !item.correct);
    return false;
  }
}
