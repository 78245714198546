/**
 * Created by Maxim Bon. on 01/05/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedPagesComponent } from './shared-pages.component';
import { SharedModule } from '../shared/shared.module';
import { EditTestComponent } from './edit-test/edit-test.component';
import { CumulativeTestResolver } from './edit-test/cumulative-test.resolver';
import { TestIncludedTopicsResolver } from './edit-test/test-included-topics.resolver';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { SaveTestPopupComponent } from './edit-test/save-test-popup/save-test-popup.component';
import { EditTestNamePopupComponent } from './edit-test/edit-test-name-popup/edit-test-name-popup.component';
import { MatRadioModule } from '@angular/material/radio';
import { ConfirmTopicsPopupComponent } from './edit-test/confirm-topics-popup/confirm-topics-popup.component';
import { CoursesComponent } from './courses/courses.component';
import { CreateCoursePopupComponent } from './courses/create-course-popup/create-course-popup.component';
import { DeleteCoursePopupComponent } from './courses/delete-course-popup/delete-course-popup.component';
import { CourseStudentProfileComponent } from './courses/course-student-profile/course-student-profile.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { CurriculumManagementComponent } from './curriculum-managment/curriculum-management.component';
import { LessonPlanComponent } from './subject-details/paper-details/lesson-plan/lesson-plan.component';
import { CurriculumManagmentResolver } from './curriculum-managment/curriculum-managment.resolver';
import { CurriculumManagmentCardComponent } from './curriculum-managment/curriculum-managment-card/curriculum-managment-card.component';
import { SunburstComponent } from './subject-details/sunburst/sunburst.component';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { UploadFilesService } from '../shared/components/upload-files/upload-files.service';
import { CourseFilterPipe } from './course-details/add-course-popup/course-filter.pipe';
// import { CourseFiltersComponent } from './course-filters/course-filters.component';
import { TeacherStudentNotesComponent } from './subject-details/paper-details/teacher-student-notes/teacher-student-notes.component';
import { StudentNotesService } from './subject-details/paper-details/teacher-student-notes/student-notes.service';
import { MathModule } from '../shared/math-ml/math.module';
import { TeacherGuidanceNotesComponent } from './subject-details/paper-details/teacher-guidance-notes/teacher-guidance-notes.component';
import { TestQuestionViewComponent } from './edit-test/test-question-view/test-question-view.component';
import { TestQuestionItemComponent } from './edit-test/test-question-view/test-question-item/test-question-item.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { QuestionTypeDropdownComponent } from './edit-test/question-type-dropdown/question-type-dropdown.component';
import { EditPaperNamePopupComponent } from './subject-details/paper-details/edit-paper-name-popup/edit-paper-name-popup.component';
import { CreateEditAboutPaperComponent } from './subject-details/paper-details/create-edit-about-paper/create-edit-about-paper.component';
import { DeleteAboutPaperPopupComponent } from './subject-details/paper-details/delete-about-paper-popup/delete-about-paper-popup.component';

@NgModule({
  declarations: [
    SharedPagesComponent,
    EditTestComponent,
    SaveTestPopupComponent,
    EditTestNamePopupComponent,
    ConfirmTopicsPopupComponent,
    CoursesComponent,
    CreateCoursePopupComponent,
    LessonPlanComponent,
    CurriculumManagementComponent,
    CurriculumManagmentCardComponent,
    SunburstComponent,
    CourseFilterPipe,
    DeleteCoursePopupComponent,
    CourseStudentProfileComponent,
    // CourseFiltersComponent,
    TeacherStudentNotesComponent,
    TeacherGuidanceNotesComponent,
    TestQuestionViewComponent,
    TestQuestionItemComponent,
    QuestionTypeDropdownComponent,
    EditPaperNamePopupComponent,
    CreateEditAboutPaperComponent,
    DeleteAboutPaperPopupComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatFormFieldModule,
    MatRippleModule,
    MatRadioModule,
    MatTabsModule,
    MatDialogModule,
    MatMenuModule,
    RouterModule,
    MathModule,
    MatProgressSpinnerModule,
  ],
  exports: [
    LessonPlanComponent,
    CourseFilterPipe,
    TeacherStudentNotesComponent,
    //  CourseFiltersComponent,
    TeacherGuidanceNotesComponent
  ],
  providers: [
    CumulativeTestResolver,
    TestIncludedTopicsResolver,
    MatDatepickerModule,
    CurriculumManagmentResolver,
    UploadFilesService,
    StudentNotesService,
  ],
})
export class SharedPagesModule {}
