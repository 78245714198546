<!--
 Created by Daniil Vat. on 18/2/2021
 Copyright © 2021 Curriculum Ltd. All rights reserved.
-->
<div class="curr-expansion-panel-question">
  <div class="panel" [ngClass]="{ open: isShowed, error: isError }" (clickOutside)="closeDropDown()">
    <div class="custom-select" [class.disabled]="isDisabled" (click)="toggleDropdown()">
      <div class="content" fxLayoutAlign="space-between center" fxLayout="row">
        <div class="custom-select__trigger mat-h3 text">
          <div class="view-row-title-block">
            <span *ngIf="questionNumber">{{question.isMaster ? 'Introduction' : 'Question'}} {{ questionNumber }}</span>
            <div class="markers-list" *ngIf="!question.isMaster">
              <div class="markers-list-item" *ngIf="question.markType">{{ question.markType === 'AI_MARKED' ? 'AI Marked' : 'Self Marked' }}</div>
              <div class="markers-list-item"
                   *ngIf="question.isPastExamQuestion && (question.examinationBoard || question.questionYear)">
                Exam Board {{ question.questionYear }}{{question.examinationBoard ? ',' : ''}} {{ question.examinationBoard.name }}
              </div>
            </div>
          </div>
        </div>
        <div fxLayoutAlign="end center" fxLayout="row">
          <div class="arrow-wrapper m-r-4"><span class="arrow"></span></div>
        </div>
      </div>
    </div>

    <div *ngIf="isShowed" class="options" [@enterAnimation]>
      <div class="container">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
