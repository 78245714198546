/**
 * Created by Alex Poh. on 23/03/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class IconRegistrarService {
  constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {}

  public registerIcons() {
    this.register('app-logo', 'assets/images/logo.svg');
    this.register('home', 'assets/images/home.svg');
    this.register('stud-notes', 'assets/images/stud-notes.svg');
    this.register('arrow_down', 'assets/images/arrow_down.svg');
    this.register('arrow_down_new', 'assets/images/arrow-down1.svg');
    this.register('down', 'assets/images/down.svg');
    this.register('arrow_up', 'assets/images/arrow_up.svg');
    this.register('up', 'assets/images/up.svg');
    this.register('cog', 'assets/images/cog.svg');
    this.register('logout', 'assets/images/logout.svg');
    this.register('check', 'assets/images/check.svg');
    this.register('bookmark', 'assets/images/bookmark.svg');
    this.register('save-bookmark', 'assets/images/mdi_bookmark.svg');
    this.register('contacts', 'assets/images/contacts.svg');
    this.register('folder', 'assets/images/folder.svg');
    this.register('list', 'assets/images/list.svg');
    this.register('view-module', 'assets/images/view_module.svg');
    this.register('planet', 'assets/images/planet.svg');
    this.register('university', 'assets/images/university.svg');
    this.register('bell', 'assets/images/bell.svg');
    this.register('building', 'assets/images/building.svg');
    this.register('school-hat', 'assets/images/school-hat.svg');
    this.register('avatar', 'assets/images/avatar.svg');
    this.register('statistic', 'assets/images/statistic.svg');
    this.register('arrow-statistic', 'assets/images/arrow-statistic.svg');
    this.register('arrow-statistic-down', 'assets/images/arrow-statistic-down.svg');
    this.register('circle', 'assets/images/circle.svg');
    this.register('lens-outlined', 'assets/images/lens-outlined.svg');
    this.register('lens-filled', 'assets/images/lens-filled.svg');
    this.register('school-logo', 'assets/images/school-logo.svg');
    this.register('logo-dark', 'assets/images/logo-dark.svg');
    this.register('logo-rv', 'assets/images/rv-ac.svg');
    this.register('logo-c', 'assets/images/logo-c.svg');
    this.register('logo-new', 'assets/images/logo-new.svg');
    this.register('dashboard', 'assets/images/dashboard.svg');
    this.register('students', 'assets/images/students.svg');
    this.register('hat', 'assets/images/hat.svg');
    this.register('settings', 'assets/images/settings.svg');
    this.register('student-review', 'assets/images/student-review.svg');
    this.register('files', 'assets/images/files.svg');
    this.register('audio', 'assets/images/audio.svg');
    this.register('user', 'assets/images/user.svg');
    this.register('cumulative-test-create', 'assets/images/cumulative-test-create.svg');
    this.register('trash', 'assets/images/trash.svg');
    this.register('download', 'assets/images/download.svg');
    this.register('edit', 'assets/images/edit.svg');
    this.register('notes', 'assets/images/notes.svg');
    this.register('clear', 'assets/images/mdi_clear.svg');
    this.register('arrow-right', 'assets/images/arrow-right.svg');
    this.register('arrow-left', 'assets/images/arrow-left.svg');
    this.register('icon-success-checkmark', 'assets/images/icon-success-checkmark.svg');
    this.register('warning-icon', 'assets/images/warning-icon.svg');
    this.register('exclamation-mark', 'assets/images/exclamation-mark.svg');

    this.register('drop-files', 'assets/images/drop-files.svg');

    this.register('retry', 'assets/images/retry.svg');
    this.register('close', 'assets/images/close.svg');
    this.register('close-in-circle', 'assets/images/close-in-circle.svg');
    this.register('completed-upload', 'assets/images/completed-upload.svg');
    this.register('watch-later', 'assets/images/watch_later-black-18dp.svg');
    this.register('watch', 'assets/images/watch.svg');

    this.register('icon-success', 'assets/images/icon-success.svg');

    this.register('icon-done', 'assets/images/icon-done.svg');

    this.register('warn', 'assets/images/warn.svg');
    this.register('cancel', 'assets/images/mdi_cancel.svg');
    this.register('image', 'assets/images/image.svg');
    this.register('file', 'assets/images/doc.svg');
    this.register('link', 'assets/images/link.svg');
    this.register('play', 'assets/images/play.svg');
    this.register('plus', 'assets/images/plus.svg');
    this.register('search_black', 'assets/images/search_black.svg');

    this.register('view-icon', 'assets/images/view-icon.svg');
    this.register('breadcrumb-arrow', 'assets/images/breadcrumb-arrow.svg');

    this.register('calendar', 'assets/images/calendar.svg');
    this.register('sort-by-alpha', 'assets/images/sort-by-alpha.svg');
    this.register('swap-vert', 'assets/images/swap-vert.svg');

    this.register('arrow-down', 'assets/images/arrow-down.svg');
    this.register('mdi-arrow-right', 'assets/images/mdi-arrow-right.svg');
    this.register('sandwich', 'assets/images/sandwich.svg');
    this.register('graph', 'assets/images/graph.svg');

    this.register('legend-line', 'assets/images/legend-line.svg');
    this.register('microphone', 'assets/images/microphone.svg');
    this.register('pause', 'assets/images/pause.svg');
    this.register('stop', 'assets/images/stop.svg');
    this.register('open_in', 'assets/images/open_in.svg');
    this.register('icon-plus', 'assets/images/icon-plus.svg');

    this.register('speaker-notes', 'assets/images/speaker-notes.svg');

    this.register('report_problem', 'assets/images/report_problem.svg');

    this.register('less-than-symbol', 'assets/images/less-than-symbol.svg');
    this.register('greater-than-symbol', 'assets/images/greater-than-symbol.svg');
    this.register('visibility', 'assets/images/visibility.svg');
    this.register('coin', 'assets/images/coin.svg');

    this.register('upload-img', 'assets/images/upload-img.svg');
    this.register('zoom-icon', 'assets/images/zoom-icon.svg');
    this.register('leave-comment', 'assets/images/leave-comment.svg');
    this.register('lock', 'assets/images/lock.svg');
    this.register('assignment', 'assets/images/assignment.svg');
    this.register('library-books', 'assets/images/library-books.svg');
    this.register('view-headline', 'assets/images/view-headline.svg');
    this.register('school-logo-mobile', 'assets/images/school-logo-mobile.svg');
    this.register('info', 'assets/images/info.svg');
    this.register('info-empty', 'assets/images/info-empty.svg');
    this.register('no-student-graph-data', 'assets/images/no-student-graph-data.svg');
    this.register('done', 'assets/images/done.svg');
    this.register('access-time', 'assets/images/access-time.svg');
    this.register('thumbs-up-down', 'assets/images/thumbs-up-down.svg');
    this.register('thumb-up', 'assets/images/thumb-up.svg');
    this.register('thumb-down', 'assets/images/thumb-down.svg');
    this.register('trash-new', 'assets/images/trash-new.svg');
    this.register('bell-notification', 'assets/images/bell-notification.svg');
    this.register('lock-new', 'assets/images/lock-new.svg');
    this.register('welcome', 'assets/images/welcome.svg');
    this.register('test-finish', 'assets/images/test-finish.svg');
    this.register('test-finish-green', 'assets/images/test-finish-green.svg');
    this.register('biology-sm', 'assets/images/biology-sm.svg');
    this.register('mathematics-sm', 'assets/images/math-sm.svg');
    this.register('further-math-sm', 'assets/images/further-math-sm.svg');
    this.register('chemistry-sm', 'assets/images/chemistry-sm.svg');
    this.register('physics-sm', 'assets/images/physics-sm.svg');
    this.register('economics-sm', 'assets/images/economics-sm.svg');

    this.register('physics', 'assets/images/physics.svg');
    this.register('math', 'assets/images/math.svg');
    this.register('further-math', 'assets/images/further-math.svg');
    this.register('biology', 'assets/images/biology.svg');
    this.register('economics', 'assets/images/economics.svg');
    this.register('chemistry', 'assets/images/chemistry.svg');

    this.register('bot', 'assets/images/bot.svg');
    this.register('chart-no-data', 'assets/images/no-data-chart-image.svg');
    this.register('progress-no-data', 'assets/images/progress-not-data.svg');
    this.register('calender-no-data', 'assets/images/calender-empty.svg');
    this.register('referral-data', 'assets/images/referral.svg');
    this.register('plus-green', 'assets/images/plus-green.svg');
    this.register('google', 'assets/images/google.svg');
    this.register('info-new', 'assets/images/info-new.svg');
    this.register('math-sm', 'assets/images/math-checkout.svg');
    this.register('student', 'assets/images/student.svg');
    this.register('star', 'assets/images/star.svg');
    this.register('calendar-new', 'assets/images/calendar-new.svg');

    this.register('megaphone', 'assets/images/megaphone.svg');
    this.register('online-test', 'assets/images/online-test.svg');
    this.register('learning', 'assets/images/learning.svg');
    this.register('live-session', 'assets/images/live-session.svg');
    this.register('coin-10', 'assets/images/coins/coin-10.svg');
    this.register('progress-widget', 'assets/images/progress-widget.svg');
    this.register('camera', 'assets/images/camera.svg');
    this.register('copy', 'assets/images/copy.svg');
    this.register('watch', 'assets/images/watch.svg');
    this.register('exams', 'assets/images/exams.svg');
    this.register('confirm-test', 'assets/images/confirm-test.svg');

    this.register('meet', 'assets/images/meet.svg');
    this.register('no-events', 'assets/images/no-events.svg');
    this.register('curriculum', 'assets/images/referral-curriculum.svg');
    this.register('circleClose', 'assets/images/circle-cancel.svg');
    this.register('circleCheck', 'assets/images/circle-check.svg');
    this.register('circleWatch', 'assets/images/circle-watch.svg');
    this.register('inviteUsers', 'assets/images/invite-users.svg');

    this.register('answers', 'assets/images/answers.svg');
    this.register('timer', 'assets/images/timer.svg');
    this.register('comment-bell', 'assets/images/comment-bell.svg')

    this.register('coin-gold-4', 'assets/images/coin-gold-4.svg');
    this.register('coin-green-4', 'assets/images/coin-green-4.svg');
    this.register('arrow-circle', 'assets/images/arrow-circle.svg');
    this.register('tutorSession', 'assets/images/tutor-icon.svg');
    this.register('list-new', 'assets/images/list-new.svg');
    this.register('info-test', 'assets/images/info-test.svg');
    this.register('arrow-up', 'assets/images/arrow-up.svg');
    this.register('arrow-down-new', 'assets/images/arrow-down-new.svg');
    this.register('no-profile-image', 'assets/images/no-profile-image.svg');

    this.register('orientation-video', 'assets/images/orientation-video.svg');
    this.register('referral-program', 'assets/images/referral-program.svg');
    this.register('logout-green', 'assets/images/logout-green.svg');
    this.register('curr-logo-mobile', 'assets/images/curr-logo-mobile.svg');
    this.register('failed-payment', 'assets/images/payment_error.svg');
    this.register('self-assessment-start', 'assets/images/self-assessment-start.svg');
    this.register('right-answer', 'assets/images/right-answer.svg');
    this.register('wrong-answer', 'assets/images/wrong-answer.svg');
    this.register('failed-payment', 'assets/images/payment_error.svg');
    this.register('start-test', 'assets/images/start-test.svg');
    this.register('tv', 'assets/images/tv.svg');

    this.register('calendar-today', 'assets/images/calendar-today.svg');
    this.register('time', 'assets/images/time.svg');
    this.register('close-dialog', 'assets/images/close-dialog.svg');
    this.register('minimize', 'assets/images/minimize.svg');
    this.register('maximize', 'assets/images/maximize.svg');
    this.register('open-in-full', 'assets/images/open-in-full.svg');
    this.register('close-fullscreen', 'assets/images/close-fullscreen.svg');
    this.register('drag-pan', 'assets/images/drag-pan.svg');
    this.register('ai-file-edit', 'assets/images/ai-file-edit.svg');
    this.register('ai-file-delete', 'assets/images/ai-file-delete.svg');

    this.register('float-ignore-area', 'assets/images/float-ignore-area.svg');
    this.register('float-mark', 'assets/images/float-mark.svg');
    this.register('float-remove-selection', 'assets/images/float-remove-selection.svg');
    this.register('float-select-area', 'assets/images/float-select-area.svg');

    this.register('biology-colored', 'assets/images/topics/colored/biology.svg');
    this.register('chemistry-colored', 'assets/images/topics/colored/chemistry.svg');
    this.register('economics-colored', 'assets/images/topics/colored/economics.svg');
    this.register('english-literature-colored', 'assets/images/topics/colored/english-literature.svg');
    this.register('further-math-colored', 'assets/images/topics/colored/further-math.svg');
    this.register('geography-colored', 'assets/images/topics/colored/geography.svg');
    this.register('history-colored', 'assets/images/topics/colored/history.svg');
    this.register('psychology-colored', 'assets/images/topics/colored/psychology.svg');
    this.register('mathematics-colored', 'assets/images/topics/colored/mathematics.svg');
    this.register('physics-colored', 'assets/images/topics/colored/physics.svg');

    this.register('check-circle-mini', 'assets/images/colored/check-circle-mini.svg');
    this.register('check-close-mini', 'assets/images/colored/check-close-mini.svg');

    this.register('arrow-top-move-list', 'assets/images/arrow-top-move-list.svg');
    this.register('cross', 'assets/images/cross.svg');
    this.register('menu-book', 'assets/images/menu-book.svg');
    this.register('resizer', 'assets/images/resizer.svg');

    this.register('groups', 'assets/images/groups.svg');
    this.register('checkbook', 'assets/images/checkbook.svg');
    this.register('error', 'assets/images/error.svg');

    this.register('circle-check-new', 'assets/images/circle-check-new.svg');
  }

  private register(name: string, path: string) {
    this.iconRegistry.addSvgIcon(name, this.sanitizer.bypassSecurityTrustResourceUrl(path));
  }
}
