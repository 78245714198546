<!-- Created by Maxim B. on 14/04/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<div class="container" ngClass.lt-sm="mob-container">
  <div class="m-b-24">
    <curr-breadcrumb position="start" [path]="path"></curr-breadcrumb>
  </div>

  <div fxLayout="row" *ngIf="paperOptions.length" fxLayoutAlign="space-between center" class="m-b-16"
    fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center center">
    <div fxLayout="row" fxLayoutAlign="start center">
      <!-- <span class="mat-body-1">Create test for paper:</span>
      <curr-dropdown [options]="paperOptions" [selectedValue]="paperOptions[0]?.value" placeholder="Select paper"
        class="full-width m-l-16" fxFlex="200px" (valueChange)="selectPaper($event)"></curr-dropdown> -->
    </div>
    <curr-button class="add-test-btn" icon="icon-plus" size="big" (click)="createTest()" [isDisabled]="!selectedPaperId">{{ courseType }}</curr-button>
  </div>

  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center center">
    <h2 ngClass.gt-xs="mat-h2 m-b-0 m-l-16" ngClass.lt-sm="m-l-0">
      {{ subject.subject }} &ndash; {{ courseType }}
    </h2>
    <div class="tabs-container " fxLayoutAlign.lt-sm="end center" ngClass.lt-sm="full-width">
      <curr-dropdown [options]="ddOptions" [selectedValue]="currSelectedTabIndex + ''" class="full-width m-t-8" fxFlex
        (valueChange)="selectTab($event)" fxHide.gt-xs></curr-dropdown>
      <curr-teacher-tabs fxHide.lt-sm [tabLabels]="tabLabels" [(value)]="currSelectedTabIndex"></curr-teacher-tabs>
    </div>
  </div>
  <mat-divider class="m-b-24"></mat-divider>

  <div class="tests-container">
    <div class="block" *ngIf="currSelectedTabIndex == 0 || currSelectedTabIndex == 1">
      <div class="header-container">
        <h2 class="mat-h2 m-l-16">Requires Review</h2>
      </div>
      <div class="items-container" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="8px">
        <curr-universal-card *ngFor="let card of tests?.needCheckCard" [card]="card" [isRequiresReview]="true"></curr-universal-card>
      </div>
    </div>
    <div class="block" *ngIf="currSelectedTabIndex == 0 || currSelectedTabIndex == 2">
      <div class="header-container">
        <h2 class="mat-h2 m-l-16">Reviewed</h2>
      </div>
      <div class="items-container" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="8px">
        <curr-universal-card *ngFor="let card of tests?.checkedCard" [card]="card"></curr-universal-card>
      </div>
    </div>
    <div class="block" *ngIf="currSelectedTabIndex == 0 || currSelectedTabIndex == 3">
      <div class="header-container">
        <h2 class="mat-h2 m-l-16">Not Passed</h2>
      </div>
      <div class="items-container" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="8px">
        <curr-universal-card *ngFor="let card of tests?.notPassedCard" [card]="card"></curr-universal-card>
      </div>
    </div>
  </div>
</div>
