<!-- Created by Alex Poh. on 05/04/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<!-- Paper info extansion panel -->
<curr-paper-info
  [paper]="paper"
  [sowDescriptions]="subject.sowDescriptions"
  (editPaperName)="editPaperName()"
  (addAboutItem)="addAboutPaper()"
  (editAboutItem)="editAboutPaper($event)"
  (deleteAbout)="onDeleteAbout($event)">
</curr-paper-info>

<!-- <div class="about-form-container" fxLayout="column" fxLayoutAlign="start start">
  <curr-button (btnClick)="openAiAssistant()" [isDisabled]="isDisabledAIBtn" [variant]="'contained'">
    AI ASSISTANT
  </curr-button>
</div> -->

<div class="flex-paper-section m-t-32">
  <div class="flex-paper-first">
    <div #sidebarHeader class="mat-h2 full-width paper-title">Paper {{ num }}</div>
    <mat-list currScrollable currCustomScroll class="learning-objectives-list" *ngIf="navigationTree">
      <ng-container *ngFor="let unitLevel of navigationTree.unitLevels; let i = index">
        <mat-list-item class="title-selector">
          <div class="mat-body-2">{{ unitLevel.unit.sequence_num }}. {{ unitLevel.unit.title }}</div>
        </mat-list-item>
        <ng-container *ngFor="let topicLevel of unitLevel.topicLevels">
          <mat-list-item class="title-selector m-l-16">
            <div class="mat-body-2">{{ unitLevel.unit.sequence_num }}.{{ topicLevel.topic.sequence_num }}. {{
              topicLevel.topic.title }}</div>
          </mat-list-item>
          <ng-container *ngFor="let learningObjective of topicLevel.learningObjectives; let j = index">
            <div class="m-l-32">
              <mat-list-item currOffsetTop class="topic-selector" (click)="selectLearningObjective(learningObjective, topicLevel.topic.sequence_num);
                setSecNumPrefix(unitLevel.unit.sequence_num, j+1)"
                [ngClass]="selectedTopic == learningObjective ? 'glow' : ''">
                <div
                  [currMath]="unitLevel.unit.sequence_num + '.' + topicLevel.topic.sequence_num + '.' + learningObjective.sequence_num + ' ' + learningObjective.title"
                  class="mat-body-2">
                </div>
<!--                <div class="mat-body-2">{{ unitLevel.unit.sequence_num }}.{{ topicLevel.topic.sequence_num }}.{{-->
<!--                  learningObjective.sequence_num }} {{ learningObjective.title + }}</div>-->
              </mat-list-item>
<!--              <mat-divider *ngIf="selectedTopic !== learningObjective && onTopTopic !== learningObjective">-->
<!--              </mat-divider>-->
            </div>
          </ng-container>
        </ng-container>
        <!--<mat-divider *ngIf="selectedTopic !== topic && onTopTopic !== topic"></mat-divider>-->
      </ng-container>
    </mat-list>
    <curr-button (btnClick)="addLearningObjective(0)" *ngIf="isNewPaper" class="m-t-8" [icon]="'plus'">ADD LEARNING OBJECTIVE
    </curr-button>
  </div>
  <div class="flex-paper" *ngIf="selectedTopic">
    <div #learningObjectiveDetails>
      <mat-card class="card" [ngClass]="selectedMenuIndex === 2 ? 'tab-with-overflow' : ''">
        <mat-tab-group [dynamicHeight]="true" mat-align-tabs="center" [(selectedIndex)]="selectedMenuIndex"
          animationDuration="0ms" class="nav-panel tab-element custom-ink-size">
          <mat-tab label="SCHEME OF WORK">
            <ng-template matTabContent>
              <div class="tab-content">
                <curr-scheme-of-work *ngIf="fetchedLearningObjective" [lo]="fetchedLearningObjective"
                  (topicUpdated)="onTopicUpdate($event)" (newEntityWasAdded)="addLearningObjective($event)"
                  [subject]="subject" [secNumPrefix]="secNumPrefix" [paperResponse]="paperResponse">
                </curr-scheme-of-work>
              </div>
            </ng-template>
          </mat-tab>
          <mat-tab label="TEACHER GUIDANCE">
            <div class="tab-content">
              <ng-template matTabContent>
                <curr-teacher-guidance-notes [topic]="fetchedLearningObjective"></curr-teacher-guidance-notes>
              </ng-template>
            </div>
          </mat-tab>
          <mat-tab label="STUDENT HANDOUT">
            <div class="tab-content stud-notes">
              <ng-template matTabContent>
                <curr-teacher-student-notes [topic]="fetchedLearningObjective"></curr-teacher-student-notes>
              </ng-template>
            </div>
          </mat-tab>
          <mat-tab label="LESSON PLAN">
            <div class="tab-content">
              <ng-template matTabContent>
                <curr-lesson-plan [learningObjective]="fetchedLearningObjective"
                  (learningObjectiveChanged)="selectLearningObjective($event)"
                  [learningObjectives]="paperResponse.learningObjectives"></curr-lesson-plan>
              </ng-template>
            </div>
          </mat-tab>
          <mat-tab label="LEARNING RESOURCES">
            <ng-template matTabContent>
              <div class="tab-content">
                <curr-learning-resources [learningObjective]="fetchedLearningObjective"
                  (learningObjectiveUpdated)="onTopicUpdate($event)"></curr-learning-resources>
              </div>
            </ng-template>
          </mat-tab>
          <mat-tab label="TEST QUESTIONS">
            <div class="tab-content">
              <ng-template matTabContent>
                <curr-test-questions [subjectDetailsId]="curriculumId" [learningObjective]="fetchedLearningObjective"
                  [learningObjectives]="paperResponse.learningObjectives"></curr-test-questions>
              </ng-template>
            </div>
          </mat-tab>
          <mat-tab label="COU">
            <div class="tab-content">
              <ng-template matTabContent>
                <curr-cou [topic]="fetchedLearningObjective" [subjectDetailsId]="curriculumId"
                  (topicUpdated)="onTopicUpdate($event)" (emitQuestions)="getQuestions($event)"></curr-cou>
              </ng-template>
            </div>
          </mat-tab>
        </mat-tab-group>
      </mat-card>
    </div>
  </div>
<!--  <div fxFlex="204px" *ngIf="schoolYearCourseId">-->
<!--    <div class="m-b-8">-->
<!--      <curr-cumulative-test-create (click)="createNewCT()"></curr-cumulative-test-create>-->
<!--    </div>-->
<!--    <div class="m-b-8" *ngFor="let ct of paperResponse.cumTests; let i = index">-->
<!--      <curr-cumulative-test-card [ct]="ct" (onCardDelete)="deleteCTCard(i)"></curr-cumulative-test-card>-->
<!--    </div>-->
<!--  </div>-->
</div>
