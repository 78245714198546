/**
 * Created by Daniil Vat. on 18/2/2021
 * Copyright © 2021 Curriculum Ltd. All rights reserved.
 */

import { Component, Input, OnInit } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'curr-expansion-panel-question',
  templateUrl: './expansion-panel-question.component.html',
  styleUrls: ['./expansion-panel-question.component.scss'],
  // animations: [
  //   trigger('enterAnimation', [
  //     transition(':enter', [style({ opacity: 0 }), animate('200ms', style({ opacity: 1 }))]),
  //     transition(':leave', [style({ opacity: 1 }), animate('200ms', style({ opacity: 0 }))])
  //   ])
  // ]
})
export class ExpansionPanelQuestionComponent implements OnInit {

  @Input() isDisabled? = false;
  @Input() isShowed = true;
  @Input() question: any;
  @Input() questionNumber: string;
  isError = false;
  inEditMode = false;

  constructor() {}

  ngOnInit() {
    console.log(this.question)
  }

  toggleDropdown() {
    if (!this.isDisabled) {
      this.isShowed = !this.isShowed;
    }
  }

  closeDropDown() {
    this.isShowed = false;
  }
}
