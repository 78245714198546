/**
 * Created by Daniil V. on 04/03/21.
 * Copyright © 2021 SEVEN. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { ApplicationHttpClient } from '../../../shared/api/application-http-client';
import { TeacherGuideline } from './paper-details.interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Paper } from '../api-subject-details';

@Injectable({
  providedIn: 'root'
})
export class PaperDetailsService {
  constructor(public http: ApplicationHttpClient) {
  }

  // TeacherGuidelines CRUD
  getTeacherGuidelines(curriculumLearningObjectiveId: number): Observable<TeacherGuideline[]> {
    return this.http
      .get<{ data: { items: TeacherGuideline[] } }>(
        `/curriculumLearningObjectiveGuideliness?curriculumLearningObjectiveId=${ curriculumLearningObjectiveId }`
      )
      .pipe(map(res => res?.data?.items));
  }

  postTeacherGuideline(data: TeacherGuideline): Observable<TeacherGuideline> {
    return this.http
      .post<{ data: TeacherGuideline }>(`/curriculumLearningObjectiveGuideliness`, data)
      .pipe(map(res => res.data));
  }

  putTeacherGuideline(data: TeacherGuideline): Observable<TeacherGuideline> {
    return this.http
      .put<{ data: TeacherGuideline }>(`/curriculumLearningObjectiveGuideliness`, data)
      .pipe(map(res => res.data));
  }

  deleteTeacherGuideline(teacherGuidelineId: number): Observable<null> {
    return this.http.delete<null>(`/curriculumLearningObjectiveGuideliness/${ teacherGuidelineId }`);
  }
  getPapers():Observable<Paper> {
    return this.http.get('curriculumPapers?curriculum_id=1128')
  }
}
