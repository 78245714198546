<!-- Created by Maxim Bon. on 13/05/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<div class="create-edit-about-paper">
  <div class="change-about-form">
    <curr-input
      placeholder="Input Label"
      [formGroup]="form"
      controlName="aboutName"
      class="full-width about-name"
      maxlength="100"
      userHint="{{form.controls['aboutName'].value ? form.controls['aboutName'].value.length : 0}}/100"
      [errorMessage]="form.controls['aboutName'].hasError('required') ? 'Paper Name is required' : 'Paper Name max length = 100 characters'"
    ></curr-input>
    <curr-textarea
      [formGroup]="form"
      rows="4"
      class="full-width"
      controlName="aboutText"
      label="Comments"
      hint="Click and Drag the corner to make the Text Area bigger"
      [errorMessage]="form.controls['aboutText'].hasError('required') ? 'Paper Name is required' : ''">
    </curr-textarea>
  </div>
  <div class="actions-section">
    <curr-button variant="text" (click)="cancel()" size="big">CANCEL</curr-button>
    <curr-button variant="contained" [isDisabled]="!form.valid" (btnClick)="save()" size="big">SUBMIT</curr-button>
  </div>
</div>
