/**
 * Created by Maxim Bon. on 12/05/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ApplicationHttpClient } from '../../shared/api/application-http-client';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TestIncludedTopicsResolver implements Resolve<any> {
  constructor(private httpClient: ApplicationHttpClient) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any[]> | Promise<any[]> | any[] {
    const paperId = route.paramMap.get('paperId');
    const school_year_course_id = route.paramMap.get('schoolYearCourseId');

    if (paperId) {
      return this.httpClient
        .get(`/curriculumCumTests/getLearningObjectivesForNewCumulativeTest?curriculum_paper_id=${paperId}&school_year_course_id=${school_year_course_id}`)
        .pipe(
          map((res: any) => {
            const d = res.data;
            if (d.items.length) {
              d.items.forEach(i => {
                i.alreadyUsed = false;
              });
            }
            return d.items;
          })
        );
    } else {
      return [];
    }
  }
}
