/*
 * Created by Dmytro Sav. on 4/5/2020
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { Paper } from '../../api-subject-details';
import { KeyValue } from '../../../../shared/api/models/key-value';

@Component({
  selector: 'curr-paper-info',
  templateUrl: './paper-info.component.html',
  styleUrls: ['./paper-info.component.scss'],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('200ms', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('200ms', style({ opacity: 0 }))])
    ])
  ]
})
export class PaperInfoComponent {
  @Input() paper: Paper;
  @Input() sowDescriptions: KeyValue[];
  @Output() editPaperName = new EventEmitter<void>();
  @Output() addAboutItem = new EventEmitter<void>();
  @Output() editAboutItem = new EventEmitter<{ item: KeyValue; index: number }>();
  @Output() deleteAbout = new EventEmitter<number>();

  onEditPaperName() {
    this.editPaperName.emit();
  }

  onAddAboutItem() {
    this.addAboutItem.emit();
  }

  onEditAboutItem(item: KeyValue, index: number) {
    this.editAboutItem.emit({ item, index });
  }

  onDeleteAboutItem(index: number) {
    this.deleteAbout.emit(index);
  }
}
