<!--
 Created by Dmytro Sav. on 4/28/2020
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->

<div class="curr-students-list" *ngIf="studentData">
  <curr-expansion-panel
    title="{{ studentData.data.firstName + ' ' + studentData.data.lastName }}"
    [subTitle]="'('+reviewedStudentQuestions+'/'+studentData.questionsWithAnswers.length+' reviewed)'"
    [isShowed]="isFirst">

    <div class="panel-content">
      <ng-container *ngFor="let question of studentData.questionsWithAnswers; let i = index">
        <curr-expansion-panel-question
          class="expansion-panel-question"
          [question]="question"
          [questionNumber]="(i + 1).toString()"
          [isShowed]="i === 0">
          <curr-students-list-question [question]="question"></curr-students-list-question>
        </curr-expansion-panel-question>

        <div *ngIf="question.children.length > 0">
          <ng-container *ngFor="let questionSecondLevel of question.children; let j = index">
            <curr-expansion-panel-question
              class="expansion-panel-question"
              [question]="questionSecondLevel"
              [questionNumber]="(i + 1) + '.' + (j + 1)"
              [isShowed]="false">
              <curr-students-list-question [question]="questionSecondLevel"></curr-students-list-question>
            </curr-expansion-panel-question>

            <div *ngIf="questionSecondLevel.children.length > 0">
              <ng-container *ngFor="let questionThirdLevel of questionSecondLevel.children; let k = index">
                <curr-expansion-panel-question
                  class="expansion-panel-question"
                  [question]="questionThirdLevel"
                  [questionNumber]="(i + 1) + '.' + (j + 1) + '.' + (k + 1)"
                  [isShowed]="false">
                  <curr-students-list-question [question]="questionThirdLevel"></curr-students-list-question>
                </curr-expansion-panel-question>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </ng-container>      
    </div>
  </curr-expansion-panel>
</div>
