/*
 * Created by Dmytro Sav. on 4/28/2020
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, Input, OnInit } from '@angular/core';
import { QuestionType } from '../../../student/test.model';

@Component({
  selector: 'curr-students-list',
  templateUrl: './students-list.component.html',
  styleUrls: ['./students-list.component.scss']
})
export class StudentsListComponent implements OnInit {

  isResultImage: boolean = false;
  qt = QuestionType;

  @Input() studentData: any;
  @Input() isFirst = false;

  reviewedStudentQuestions = 0;

  constructor() {}

  ngOnInit() {
    this.checkReviewedQuestions();
  }

  checkReviewedQuestions() {
    this.studentData.questionsWithAnswers.forEach(item => {
      const isPlus = item.newStudentAnswers && item.newStudentAnswers.reviewState === 'APPROVED';
      if (isPlus) this.reviewedStudentQuestions++;
    });
  }
}
