/**
 * Created by Alex Poh. on 20/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, OnInit, ViewChild } from '@angular/core';
import { of, Subject, switchMap, takeUntil } from 'rxjs';
import { TaskListComponent } from 'src/app/shared/components/task-list/task-list.component';
import { ISAChart, ISAChartSubject } from './school-admin-dashboard.interface';
import * as moment from 'moment';
import { SchoolAdminDashboardService } from './school-admin-dashboard.service';

@Component({
  selector: 'curr-school-admin-dashboard',
  templateUrl: './school-admin-dashboard.component.html',
  styleUrls: ['./school-admin-dashboard.component.scss']
})
export class SchoolAdminDashboardComponent implements OnInit {

  @ViewChild('tasksList')
  tasksList!: TaskListComponent;
  tempFilterOptions = [ 
    { value: 1, label: 'Biology – Year 10' },
    { value: 2, label: 'Chemistry – Year 10' },
    { value: 3, label: 'Math – Year 10' },
    { value: 4, label: 'Astronomy – Year 10' },
    { value: 5, label: 'Literature – Year 10' },
    { value: 6, label: 'History – Year 10' },
  ];
  preselectedCourseId: string;

  assessmentChartSubjects = [];
  assessmentResultsMark = '';
  assessmentResultDate = '';
  assessmentResultsChart = [];
  assessmentResultsChartMonth = [];
  assessmentChartLoading = true;
  assessmentChartNoData = false;

  unsubscribe: Subject<void> = new Subject<void>();

  constructor(private schoolAdminDashboardService: SchoolAdminDashboardService) {}

  ngOnInit(): void {
    this.getAssessmentChartData();
  }

  addNewTask(): void {
    this.tasksList.addNew();
  }

  assessmentChartSubjectsChange(value: ISAChartSubject) {
    this.getAssessmentChartWithoutSubjects(value.subject, value.studyYearId, value.courseId);
  }

  private getAssessmentChartData(): void {
    this.schoolAdminDashboardService
      .getAssessmentChartSubjects()
      .pipe(
        switchMap((subjects) => {
          if (subjects.length > 0) {
            subjects.forEach((item) => {
              this.assessmentChartSubjects.push({ value: item, label: item.label });
            });

            return this.schoolAdminDashboardService.getAssessmentChart(
              subjects[0].subject,
              subjects[0].studyYearId,
              subjects[0].courseId,
            );
          }
          this.assessmentChartNoData = true;
          return of();
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe(
        (chartData) => {
          if (chartData) this.processAssessmentChart(chartData);
        },
        (error) => {
          this.assessmentChartLoading = false;
        },
      );
  }

  private getAssessmentChartWithoutSubjects(subject: string, studyYearId: number, courseId: number): void {
    this.schoolAdminDashboardService
      .getAssessmentChart(subject, studyYearId, courseId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (chartData) => {
          if (chartData) this.processAssessmentChart(chartData);
        },
        (error) => {
          this.assessmentChartLoading = false;
        },
      );
  }

  private processAssessmentChart(chartData: ISAChart) {
    this.assessmentResultsMark = chartData.averageGrade;
    this.assessmentResultDate = `${moment(chartData.startDate).format('D MMMM YYYY')} - ${moment(chartData.endDate).format('D MMMM YYYY')}`;
    const grades = [];
    const month = [];
    const lineChartLabels = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    chartData.grades.forEach((item) => {
      if (item.gradeLabel === '-') {
        grades.push(null)
      } else {
        grades.push(item.gradeLabel)
      };
      month.push(lineChartLabels[item.month - 1]);
    });
    this.assessmentResultsChart = grades;
    this.assessmentResultsChartMonth = month;
    this.assessmentChartLoading = false;
  }
}
