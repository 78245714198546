/**
 * Created by Maxim B. on 01/04/20.
 * Copyright © 2020 SEVEN. All rights reserved.
 */
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output, ViewChild, ViewContainerRef,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';

@Component({
  selector: 'curr-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss']
})

export class DatepickerComponent implements OnInit, OnChanges {

  @ViewChild('customInput', { read: ViewContainerRef, static: true }) public customInput: ViewContainerRef;
  @Input() isBordered = true;
  @Input() placeholder = '';
  @Input() value: string | number;
  @Input() controlName: string;
  @Input() errorMessage = '';
  @Input() prefix = '';
  @Input() isGreyBackground = false;
  @Input() isDisabled = false;
  @Input() isReadOnly = false;
  @Input() formGroup: FormGroup;
  @Input() isCentered?: boolean;
  @Input() isRequired?: boolean;
  @Input() suffix?: string;
  @Input() isSuffixDisabled: boolean = false;
  @Input() userHint = '';
  @Input() activeFromToday  = false;
  @Output() valueChange = new EventEmitter<string | number | null>();
  @Output() onInputBlur = new EventEmitter<string | number | null>();
  @Output() onSuffixClick = new EventEmitter<void>();

  constructor(
    private fb: FormBuilder) {
  }

  daysFilter = (date: Date | null): boolean => {
    if (this.activeFromToday) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date && date >= today;
    }
    return true;
  };

  ngOnInit(): void {
    if (!this.formGroup) {
      this.controlName = 'item' + Date.now() + Math.random();
      this.formGroup = this.fb.group({
        [this.controlName]: new FormControl({ value: this.value, disabled: this.isDisabled })
      });
    }

    // Set validation rules
    if (this.isRequired) {
      this.formGroup.controls[this.controlName].setValidators([Validators.required]);
    }
  }

  changeValue(val: any) {
    this.valueChange.emit(val);
  }

  updateValue() {
    if (this.formGroup.controls[this.controlName]) {
      const val = this.formGroup.controls[this.controlName].value;
      this.valueChange.emit(val);
    }
  }

  ngOnChanges(): void {
    if (this.formGroup && this.value?.toString().length) {
      this.formGroup.controls[this.controlName].setValue(this.value);
    }
    if (this.formGroup) {
      if (this.isDisabled) {
        this.formGroup.controls[this.controlName].disable();
      } else {
        this.formGroup.controls[this.controlName].enable();
      }
    }
  }

  onLostFocus() {
    if (this.formGroup.controls[this.controlName]) {
      const val = this.formGroup.controls[this.controlName].value;
      this.onInputBlur.emit(val);
    }
  }

  suffixClicked(event): void {
    if (this.isSuffixDisabled) {
      return;
    }
    event.stopPropagation();
    this.onSuffixClick.emit();
  }

  focusInput(): void {
    this.customInput.element.nativeElement.focus();
  }
}
