import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';

export interface StudentLessonCard {
  courseStudentId?: number;
  dateText?: number;
  dateTime?: number;
  testId?: number;
  testState?: any;
  title?: string;
  subjectId: number;
  learningObjectiveTitle?: string;
  subject?: string;
  learningObjectiveId?: number;
  courseId?: number;
  curriculumId?: number;
  canStartSA?: boolean;
  topic?: object;
  learningObjectives?: [];
}

export interface ISubjectTopics {
  id: number;
  can_be_downloaded?: boolean;
  curriculum_paper_id?: number;
  is_offline?: boolean | null;
  is_published?: boolean;
  lobjective_cum_question_ids?: any;
  lobjective_ids?: any;
  school_year_course_id: number;
  sequenceNum?: number;
  start_time?: string;
  updated_at?: string;
  test_name?: string;
}

@Component({
  selector: 'curr-student-lesson-card',
  templateUrl: './student-lesson-card.component.html',
  styleUrls: ['./student-lesson-card.component.scss']
})
export class StudentLessonCardComponent implements OnInit {
  @Input() lesson: any;
  @Input() subjectId: number;
  @Input() subjectName: string;
  @Input() index: number;
  @Input() subjectTopics: ISubjectTopics[] | null;
  @Input() studentProfile: any;
  @Input() studentTopicTestSchoolData: any;
  readonly countOfDefaultTopicImages: number = 7;
  img: string;
  buttons = [];
  bottomTitles = [];
  subject;
  loaded: boolean = false;
  currentLOId: number;
  topicTestURL: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private commonService: CommonService,
  ) {
  }

  ngOnInit(): void {
    if (this.subjectTopics && this.studentTopicTestSchoolData) {
      const lessonTopicTest = this.subjectTopics.filter(item => {
        return item.test_name.replace('Topic Test','').trim() === this.lesson.topic.title;
      });

      if (lessonTopicTest.length > 0) {
        this.topicTestURL = `/courses-details/${this.subjectId}/cumulative-test/${ lessonTopicTest[0].id }/${ this.studentProfile.id }/${ this.studentTopicTestSchoolData.schoolYearCourseStudentId }`;
      }
    }
    this.img = this.generateImgUrl().toLowerCase();
    // this.createButtons();
    this.createBottomTitles();
    this.subject = this.route.snapshot.data.subject;
    this.commonService.nextLearningObjective$.next(null);
  }

  generateImgUrl(): string {
    let fileName = this.subjectName;
    if (this.subjectName.includes(' ')) {
      fileName = this.subjectName.replace(' ', '-');
    }
    if (this.subjectName.includes('-')) {
      this.subjectName = this.subjectName.replace('-', ' ');
    }
    return '../../../../assets/images/topics/' + fileName + (this.index % this.countOfDefaultTopicImages) + '.svg';
  }

  // private createButtons() {
  //   if (this.lesson.testState === 'INCOMPLETED' && this.lesson.canStartSA) {
  //     this.buttons.push({
  //       label: 'START SELF ASSESSMENT',
  //       callback: this.goToTest.bind(this)
  //     });
  //   }
  //   if (this.lesson.testState === 'COMPLETED') {
  //     this.buttons.push({
  //       label: 'VIEW RESULT',
  //       callback: this.goToResults.bind(this)
  //     });
  //     if (this.lesson.canStartSA) {
  //       this.buttons.push({
  //         label: 'RE DO',
  //         callback: this.goToTest.bind(this)
  //       });
  //     }
  //   }
  // }

  // goToTest() {
  //   const id = this.lesson.courseId;
  //   this.router.navigate([
  //     // `/subjects/${id}/self-assessment/${this.lesson.learningObjectiveId}/${this.lesson.courseStudentId}/${this.lesson.curriculumId}`
  //     `/courses-details/${ id }/self-assessment/${ this.lesson.learningObjectiveId }/${ this.lesson.courseStudentId }`
  //   ]);
  // }

  goToTest(): void {
    this.commonService.nextLearningObjective$.next(this.lesson.topic.continue_data);
    if (this.lesson.topic.continue_data.materialType) {
      if (this.lesson.topic.continue_data.materialType === 'SA_TEST') {
        this.router.navigate([`courses-details/${ this.subjectId }/self-assessment/${ this.lesson.topic.continue_data.learningObjectiveId }`]);
      }
    } else {
      this.goToLearningResources(this.subjectId, true);
    }
  }

  goToReview(): void {
    this.router.navigate([
      `courses-details/learning-resources/prior/${ this.subject.paper.curriculum_id }/${ this.subjectId }/${ this.lesson.topic.id }/prior`
    ]);
  }

  goToResults() {
    this.router.navigate([
      `/courses-details/sa-review/${ this.lesson.testId }/${ this.lesson.curriculumId }/${ this.lesson.courseStudentId }`
    ]);
  }


  private createBottomTitles() {
    this.bottomTitles.push({
      icon: 'icon-success-checkmark',
      label: 'Self Assessment Complete',
      value: '',
      isAttention: true,
      isBold: true
    });
    this.bottomTitles.push({
      icon: '',
      label: 'Subject:',
      value: this.lesson.subject,
      isAttention: false,
      isBold: false
    });
  }

  goToLearningResources(id, toCurrentTest = false): void {
    console.log(id)
    const topicId = this.lesson.topic.id;
    // if(toCurrentTest) {
    //   topicId = this.subject.learningObjectives.filter(lo => lo.status === 'CURRENT')[0]?.curriculum_topic_id;
    // }
    this.router.navigate([
      `courses-details/learning-resources/prior/${ this.subject.paper.curriculum_id }/${ id }/${ topicId }/prior`
    ]);
  }

  goToTopicTest(): void {
    this.router.navigate([this.topicTestURL]);
  }

  isToday() {
    const { dateTime } = this.lesson;
    const d = new Date(dateTime);
    const t = new Date();
    return d.getFullYear() === t.getFullYear() && d.getMonth() === t.getMonth() && d.getDate() === t.getDate();
  }

  lessonClass() {
    return this.lesson.testState !== 'COMPLETED' ? 'future' : 'complete';
  }
}
