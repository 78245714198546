/**
 * Created by Alex Poh. on 26/03/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, first, tap } from 'rxjs/operators';
import { SignUpInputData } from './sign-up-school/sign-up-input-data';
import { LocalStorageService } from 'ngx-localstorage';
import { User } from 'src/app/shared/user';
import { ApplicationHttpClient, IRequestOptions } from 'src/app/shared/api/application-http-client';
import { environment } from 'src/environments/environment';
import { BasicResponse } from 'src/app/shared/api/basic-response';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthService {
  userExistence$: BehaviorSubject<boolean> = new BehaviorSubject(undefined);

  constructor(private httpClient: ApplicationHttpClient,
              private localStorageService: LocalStorageService,
              private router: Router,) {
  }

  checkUserExistence(inputData?: SignUpInputData): Observable<BasicResponse> {
    const params = inputData || this.getTemporaryUserData();
    return this.httpClient.get('/auth/user/check', {params} as IRequestOptions);
  }

  initiateUserByEmail(inputData: SignUpInputData): Observable<any> {
    let schoolUrl = location.hostname;

    if (schoolUrl === 'localhost') {
      schoolUrl = 'develop.curriculum.com';
    }

    if(inputData.referralCode) {
      return this.httpClient.get('/auth/user/send-otp', {
        params: {
          email: inputData.email,
          referralCode: inputData.referralCode,
          schoolUrl,
        } as any
      });
    }
    return this.httpClient.get('/auth/user/send-otp', {
      params: {
        email: inputData.email,
        schoolUrl,
      } as any
    });
  }

  startAuthProcess(inputData: SignUpInputData): Observable<any> {
    return this.initiateUserByEmail(inputData)
  }

  saveTemporaryUserData(inputData: SignUpInputData) {
    this.localStorageService.set('sign-up-data', inputData);
  }

  getTemporaryUserData(): SignUpInputData {
    return this.localStorageService.get('sign-up-data');
  }

  finalizeSignIn(code: string): Observable<any> {
    const params = {
      grant_type: 'password',
      username: this.getTemporaryUserData().email,
      password: environment.auth.username,
      otp: code
    };
    const headers = new HttpHeaders({
      'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      Authorization: 'Basic ' + btoa('barClientIdPassword:' + environment.auth.username)
    });
    return this.httpClient.http
      .post<any>(`${environment.serverUrl}/oauth/token`, null, {
        params,
        headers
      })
      .pipe(
        tap(res => {
          if (res.access_token) {
            this.saveToken(res.access_token);
          }
          return res;
        }),
        catchError(err => {
          if(err) {
            return of({ invalidCode: true })
          }
        })
      );
  }

  getCurrentUser(): Observable<User> {
    return this.httpClient.get('/auth/user/me');
  }


  getUserProfile(): Observable<any> {
    return this.httpClient.get('/profile/');
  }

  saveToken(token) {
    this.localStorageService.set('token', `Bearer ${ token }`);
  }

  getToken(): string {
    return this.localStorageService.get('token');
  }

  addPaymentMethod(paymentMethodId): Observable<any> {
    return this.httpClient.post('/auth/user/sign-up-add-payment-method',
      {}, { params: { paymentMethodId }} as any);
  }
  addSecurePaymentMethod(paymentMethodId): Observable<any>{
    return this.httpClient.post('/auth/user/sign-sign-up-create-subscription',
      {paymentMethodId});
  }

  smartNavigate(url: string): void {
    if (this.router.url !== url) {
      this.router.navigateByUrl(url);
    }
  }

  checkUserAuthProgress(): void {
    this.getCurrentUser()
      .pipe(
        first(),
      )
      .subscribe((res: any) => {
        const data = JSON.parse(localStorage.getItem('sign-up-data'));
        const hasPaid = JSON.parse(localStorage.getItem('hasPaid'));
        if (res?.message === 'user not found' && !data?.email) {
          this.smartNavigate('/sign-up');
        }
        else if (res?.message === 'user not found' && data?.email) {
          this.smartNavigate('/code');
        }
        else {
          // @TODO: add || !res?.hasPaid after payment part finalized
          if (!res.chosenYearId || !!!hasPaid) {
            this.smartNavigate('/sign-up/checkout');
          }
          // @TODO: add && res?.hasPaid after payment part finalized
          else if (res.chosenYearId && !!hasPaid) {
            this.smartNavigate('/sign-up/chat');
          }
          else {
            this.smartNavigate('/sign-up');
          }
        }
    })
  }

  updateFcmToken(token: string): Observable <any> {
    const headers = new HttpHeaders({
      Authorization: this.getToken(),
    });
    return this.httpClient.put(`/notification/update-fcm-tokens?fcm_token=${token}`, {}, { headers });
  }
}
