<!--
 Created by Dmytro Sav. on 4/28/2020
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->

<div class="intro-section" *ngIf="question.isMaster">
  <div class="intro-question" [currMath]="question.question"></div>
</div>

<div class="panel-content-inside curr-students-list-question" *ngIf="!question.isMaster">
  <div class="panel-content-inside-item">
    <curr-review-test-question-card
      [isCumulativeTests]="true"
      [showAddToSessionBtn]="false"
      [showTitle]="false"
      [isNotViewMode]="true"
      [incomingQuestion]="question">
    </curr-review-test-question-card>

    <div class="student-data" *ngIf="question.newStudentAnswers">
      <curr-student-answer [answerData]="question.newStudentAnswers"></curr-student-answer>
    </div>
  </div>
  <div class="panel-content-inside-item">
    <div class="panel-content-item">
      <div class="item-main-title" *ngIf="!question.markType || question.markType === 'SELF_MARKED'">Student self marking</div>
      <div class="item-main-title" *ngIf="question.markType === 'AI_MARKED' && question.answerType === qt.OPEN_ENDED">AI Comments</div>

      <div class="self-mark-data" *ngIf="(!question.markType || question.markType === 'SELF_MARKED') && question.newStudentAnswers && question.newStudentAnswers.selfMark">
        <div class="self-mark-data-row" *ngIf="question.newStudentAnswers.selfMark.selfMarkSchemas.length > 0 && checkHasNotCorrectSelfMark(question.newStudentAnswers.selfMark.selfMarkSchemas)">
          <div class="row-title">Marks</div>
          <div class="row-content">
            <div class="open-ended-answers">
              <ng-container *ngFor="let selfMark of question.newStudentAnswers.selfMark.selfMarkSchemas; let i = index">
                <div class="open-ended-answers-row" *ngIf="!selfMark.correct">
                  <div class="open-ended-cell open-ended-answer">
                    <div class="open-ended-title">Answer</div>
                    <div class="open-ended-desc" [currMath]="selfMark.key"></div>
                  </div>
                  <div class="open-ended-cell open-ended-mark">
                    <div class="open-ended-title">Mark</div>
                    <div class="open-ended-desc">{{ selfMark.mark }}</div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="self-mark-data-row" *ngIf="question.newStudentAnswers.selfMark.reasons.length > 0 && question.newStudentAnswers.selfMark.reasons[0]">
          <div class="row-title">Student Provided Insight</div>
          <div class="row-content">
            <div class="reason" *ngFor="let reason of question.newStudentAnswers.selfMark.reasons; let i = index">{{ reason }}</div>
          </div>
        </div>
        <div class="self-mark-data-row" *ngIf="question.newStudentAnswers.selfMark.comment">
          <div class="row-title">Student Comments</div>
          <div class="row-content" [currMath]="question.newStudentAnswers.selfMark.comment"></div>
        </div>
      </div>

      <div *ngIf="question.newStudentAnswers">
        <div class="ai-comments" *ngIf="question.markType === 'AI_MARKED' && question.answerType === qt.OPEN_ENDED">
          <div class="ai-comments-label">What did the student forget to do</div>
          <div [currMath]="question.newStudentAnswers.aiMark?.insights || 'No data'"></div>

          <div class="ai-comments-label">What are the general weaknesses identified in response</div>
          <div [currMath]="question.newStudentAnswers.aiMark?.highlights || 'No data'"></div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="bottom-section-mark" *ngIf="question.newStudentAnswers && !question.isMaster">Marks Awarded: <span>{{question.newStudentAnswers.mark}}</span></div>
