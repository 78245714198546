/**
 * Created by Alex Poh. on 06/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  PaperResponse,
  SubjectDetails,
  LearningObjective,
  UnitResponse,
  TopicResponse
} from '../../api-subject-details';
import { KeyValue } from '../../../../shared/api/models/key-value';
import { ConfirmPopupComponent } from '../../../../shared/components/confirm-popup/confirm-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { getRestApiProvider, RestApiService } from '../../../../shared/api/rest-api.service';
import { delay, switchMap } from 'rxjs/operators';
import { ApplicationHttpClient } from '../../../../shared/api/application-http-client';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'curr-scheme-of-work',
  templateUrl: './scheme-of-work.component.html',
  styleUrls: ['./scheme-of-work.component.scss'],
  providers: [
    getRestApiProvider<LearningObjective>('curriculumLearningObjectives', 'curriculumLearningObjectives'),
    getRestApiProvider<{ sow_custom_fields: KeyValue[] }>(
      'updateCurriculumLearningObjectiveSow',
      'updateCurriculumLearningObjectiveSow'
    )
  ]
})
export class SchemeOfWorkComponent implements OnInit, OnChanges {
  @Input() set lo(learningObjective: LearningObjective) {
    if (this.learningObjective?.id !== learningObjective?.id) {
      this.disabledStatus = true;
    }
    this.learningObjective = learningObjective;
  }

  learningObjective: LearningObjective;
  @Input() subject: SubjectDetails;
  @Input() secNumPrefix: string;
  @Output() topicUpdated = new EventEmitter<LearningObjective>();
  @Output() newEntityWasAdded = new EventEmitter<number>();
  @Input() paperResponse: PaperResponse;
  unit: UnitResponse;
  topic: TopicResponse;
  isNewPaper = false;
  disabledStatus = true;
  isVisibleCreateField = false;
  // newCustomField: CustomField = { name: '', type: 'text', value: '' };
  newCustomField: KeyValue = { key: '', value: '' };

  curriculumNewCustomFields: KeyValue[] = [];

  customFieldsToDisplay: KeyValue[] = [];
  curriculumIds: any[] = [];

  form: FormGroup = this.fb.group({});
  unitTopicFormGroup: FormGroup;
  parentTopicOptions: { label: string; value: string }[];
  masterTopicOptions: { label: string; value: string }[];

  constructor(private route: ActivatedRoute,
              private fb: FormBuilder,
              private httpClient: ApplicationHttpClient,
              private cd: ChangeDetectorRef,
              @Inject('curriculumLearningObjectives') private learningObjectiveService: RestApiService<LearningObjective>,
              @Inject('updateCurriculumLearningObjectiveSow')
              private updateCurriculumLearningObjectiveSow: RestApiService<{ sow_custom_fields: KeyValue[] }>,
              @Inject('curriculumUnits') private unitService: RestApiService<UnitResponse>,
              @Inject('curriculumTopics') private topicsService: RestApiService<TopicResponse>,
              public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.parentTopicOptions = this.getParentTopicOptions();
    this.masterTopicOptions = this.getMasterTopicOptions([]);
    this.form = this.getFormGroup();
    this.isNewPaper = this.route.snapshot.parent?.params.subjectId === 'new';
    if (!this.learningObjective.sow_custom_fields) {
      this.learningObjective.sow_custom_fields = [];
    }
    this.form.get('title').valueChanges.subscribe(() => {
      if (this.form.get('title').value) {
        this.topicNameChanged(this.form.get('title').value);
      }
    });
    this.curriculumIds = this.subject.papers.map(i => i.curriculum_id);
    this.topicNameChanged(this.learningObjective.title);
  }

  ngOnChanges(): void {
    this.initTopicAndUnitValues();
    this.form = this.getFormGroup();
    if (!this.learningObjective.sow_custom_fields) {
      this.learningObjective.sow_custom_fields = [];
    }
    this.unitTopicFormGroup = this.getUnitTopicFormGroup();
    this.parentTopicOptions = this.getParentTopicOptions();
    this.masterTopicOptions = this.getMasterTopicOptions([]);
    this.getValuesFromFormGroup();
    this.curriculumNewCustomFields = [];
    if (this.learningObjective?.title) {
      this.topicNameChanged(this.learningObjective.title);
    }
    this.cd.detectChanges();
  }

  getFormGroup() {
    const group: any = {};
    this.customFieldsToDisplay = this.mergeCustomFields(
      this.paperResponse?.schemeWork,
      this.learningObjective?.sow_custom_fields
    );
    this.customFieldsToDisplay.forEach((field: KeyValue) => {
      group[field.key] = new FormControl(field.value || '');
    });

    group.title = new FormControl(this.learningObjective.title || '', Validators.required);
    group.sequence_num = new FormControl(this.learningObjective.sequence_num || '');
    group.duration = new FormControl(this.learningObjective.duration || '');
    group.parent_learning_objective_id = new FormControl(this.learningObjective.parent_learning_objective_id);
    group.master_learning_objective_id = new FormControl(this.learningObjective.master_learning_objective_id);
    return new FormGroup(group);
  }

  getValuesFromFormGroup() {
    // TODO: uncomment it when api is finalized
    // let schemeWork: any = { ...this.form.value };
    // schemeWork.title = undefined;
    // schemeWork.ref_num = undefined;
    // schemeWork = Object.keys(schemeWork).map(key => {
    //   return { key, value: schemeWork[key] };
    // });
    const sow_custom_fields: KeyValue[] = [];
    const defaultTopicSows: KeyValue[] = [];
    this.customFieldsToDisplay.forEach((field: KeyValue) => {
      sow_custom_fields.push({ key: field.key, value: this.form.value[field.key] });
    });
    return {
      topic: {
        sow_custom_fields,
        title: this.form.value.title,
        ref_num: 1,
        sequence_num: this.form.value.sequence_num,
        duration: this.form.value.duration,
        parent_learning_objective_id: this.form.value.parent_learning_objective_id
      },
      defaultTopicSows
    };
  }

  enableEditMode() {
    if (this.disabledStatus) {
      this.disabledStatus = false;
    }
  }

  initTopicAndUnitValues() {
    this.topic = this.paperResponse.curriculumTopics.find(topic => {
      return this.learningObjective.curriculum_topic_id === topic.id;
    });
    this.unit = this.paperResponse.curriculumUnits.find(unit => {
      return this.topic.curriculum_unit_id === unit.id;
    });
  }

  getUnitTopicFormGroup() {
    return this.fb.group({
      unit: new FormControl(this.unit.title, [Validators.required]),
      topic: new FormControl(this.topic.title, [Validators.required])
    });
  }

  editSoW(b: boolean) {
    this.disabledStatus = b;
    if (b) {
      this.saveLearningObjective();
    }
  }

  private saveLearningObjective() {
    if (this.unitTopicFormGroup.valid) {
      const newUnitName = this.unitTopicFormGroup.controls.unit.value;
      const newTopicName = this.unitTopicFormGroup.controls.topic.value;
      let oldTopicId = this.checkTopicExistence(newTopicName)?.id;
      const oldUnitId = this.checkUnitExistence(newUnitName)?.id;
      if (newUnitName !== this.unit.title) {
        this.unitService.update({ ...this.unit, title: newUnitName }, this.unit.id).subscribe(resp => {
        });
      }
      if (newTopicName !== this.topic.title) {
        oldTopicId = this.topic.id;
        this.topicsService.update({ ...this.topic, title: newTopicName }, this.topic.id).subscribe(resp => {
        });
      }
      of({ unitId: oldUnitId, topicId: oldTopicId }).subscribe(this.saveLOSubscriptionCallback);
    }
  }

  saveLOSubscriptionCallback = res => {
    this.learningObjective.sow_custom_fields = this.getValuesFromFormGroup().topic.sow_custom_fields;
    const newTopic = {
      ...this.learningObjective,
      ...this.getValuesFromFormGroup().topic,
      curriculum_topic_id: res.topicId,
      curriculum_unit_id: res.unitId
    };
    const formGroupValue = this.getValuesFromFormGroup();
    const newDefaultTopicSows = formGroupValue.defaultTopicSows;
    this.learningObjectiveService
      .update(newTopic, this.learningObjective.id)
      .pipe(
        switchMap(resp => {
          return this.updateCurriculumLearningObjectiveSow.update(
            {
              sow_custom_fields: newTopic.sow_custom_fields,
              duration: formGroupValue.topic.duration,
              ref_num: 1,
              title: formGroupValue.topic.title
            } as any,
            this.learningObjective.id
          );
        })
      )
      .subscribe(responce => {
        if (!this.subject.defaultTopicSows) {
          this.subject.defaultTopicSows = [];
        }
        this.subject.defaultTopicSows.push(...newDefaultTopicSows);
        this.topicUpdated.emit(newTopic);
        this.cd.detectChanges();
      });
  };

  createNewUnitTopicCallback = res => {
    // const formGroupValue = this.getValuesFromFormGroup();
    this.newEntityWasAdded.emit(res.topicId);
    this.unitTopicFormGroup.controls.unit.patchValue(this.unit.title);
    this.unitTopicFormGroup.controls.topic.patchValue(this.topic.title);
  };

  // TODO: check if needed
  // tslint:disable-next-line:no-unused-variable
  private updateUnitAndTopic(): Observable<{ unitId: any; topicId: any }> {
    const newUnitName = this.unitTopicFormGroup.controls.unit.value;
    const newTopicName = this.unitTopicFormGroup.controls.topic.value;
    const oldUnitId = this.checkUnitExistence(newUnitName)?.id;
    let newUnitId;
    let newTopicId;
    if (newUnitName !== this.unit.title) {
      if (!oldUnitId) {
        // Add new
        return this.unitService
          .add({
            curriculum_paper_id: this.paperResponse.paper.id,
            is_published: false,
            ref_num: 1,
            title: newUnitName
          })
          .pipe(
            switchMap(res => {
              newUnitId = res.id;
              return this.createNewTopic(newTopicName, newUnitId);
            }),
            switchMap(res => {
              newTopicId = res.id;
              return of({ unitId: newUnitId, topicId: newTopicId });
            })
          );
      } else {
        const oldTopicId = this.checkTopicExistence(newTopicName)?.id;
        if (!oldTopicId) {
          return this.createNewTopic(newTopicName, oldUnitId).pipe(
            switchMap(res => {
              return of({ unitId: oldUnitId, topicId: res.id });
            })
          );
        } else {
          return of({ unitId: oldUnitId, topicId: oldTopicId });
        }
      }
    } else {
      if (newTopicName !== this.topic.title) {
        const oldTopicId = this.checkTopicExistence(newTopicName);
        if (!oldTopicId) {
          return this.createNewTopic(newTopicName, this.unit.id).pipe(
            switchMap(res => {
              return of({ unitId: this.unit.id, topicId: res.id });
            })
          );
        } else {
          return of({ unitId: this.unit.id, topicId: oldTopicId });
        }
      } else {
        return of({ unitId: this.unit.id, topicId: this.topic.id });
      }
    }
  }

  createNewTopic(title: string, unitId: string) {
    return this.topicsService.add({
      curriculum_unit_id: unitId,
      is_published: false,
      sequence_num: this.topic.sequence_num + 1,
      title
    });
  }

  private checkTopicExistence(title: string) {
    return this.paperResponse.curriculumTopics.find(topic => topic.title === title);
  }

  private checkUnitExistence(title: string) {
    return this.paperResponse.curriculumUnits.find(unit => unit.title === title);
  }

  addCustomField() {
    this.editSoW(false);
    this.newCustomField = { key: '', value: '' };
    this.isVisibleCreateField = true;
  }

  saveValueToCustomField(val: string, i: number) {
    if (this.customFieldsToDisplay[i]) {
      this.customFieldsToDisplay[i].value = val;
      this.form.controls[this.customFieldsToDisplay[i].key].patchValue(val);
    }
  }

  saveValueToCustomFieldByFocusOut(val, i: number) {
    let newField = val.event.target.innerHTML;
    if (val.event.target.innerHTML.includes('data-mce-bogus')) {
      newField = '';
    }
    this.saveValueToCustomField(newField, i);
  }

  saveCustomField() {
    if (!this.learningObjective.sow_custom_fields) {
      this.learningObjective.sow_custom_fields = [];
    }
    // this.topic.sow_custom_fields.push({ ...this.newCustomField });
    this.customFieldsToDisplay.push(this.newCustomField);
    this.form.addControl(this.newCustomField.key, new FormControl(''));
    // this.form.addControl(this.newCustomField.value, new FormControl(''));

    this.isVisibleCreateField = false;
    // this.saveLearningObjective();
  }

  removeSpacesFromStr(str: string) {
    return str.replace(/\s/g, '_');
  }

  removeUnderscoresFromStr(str: string) {
    return str.replace(/_/g, ' ');
  }

  cancelCustomField() {
    this.isVisibleCreateField = false;
  }

  deleteCustomField(i: number) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      data: {
        questionTitle: 'Delete this Custom Field?'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.customFieldsToDisplay.splice(i, 1);
      }
    });
  }

  getParentTopicOptions() {
    return [
      { label: 'Parent topic', value: null },
      ...this.paperResponse.learningObjectives
        ?.filter(topic => topic.id !== this.learningObjective.id)
        .map(({ title, id }) => ({ label: title, value: id }))
    ];
  }

  getMasterTopicOptions(objectList: any[]) {
    if (!objectList.length) {
      return [{ label: 'Parent topic', value: null }];
    }
    return [
      { label: 'Parent topic', value: null },
      ...objectList.map(({ title, id }) => ({ label: title, value: id }))
    ];
  }

  private mergeCustomFields(curriculumCustomFields: KeyValue[], topicCustomFields: KeyValue[]): KeyValue[] {
    const curriculumCustomFieldMap = new Map();
    const topicCustomFieldsMap = new Map();
    curriculumCustomFields?.forEach(keyValue => curriculumCustomFieldMap.set(keyValue.key, ''));
    topicCustomFields?.forEach(keyValue => curriculumCustomFieldMap.set(keyValue.key, keyValue.value));
    const resultMap = new Map([...curriculumCustomFieldMap, ...topicCustomFieldsMap]);
    const result = [];
    for (const [key, value] of resultMap) {
      result.push({ key, value });
    }
    return result;
  }

  // TODO: return and fix autoupdate if neccessary.
  updateField($event: string) {
    const newTopic = { ...this.learningObjective, ...this.getValuesFromFormGroup().topic };
    const formGroupValue = this.getValuesFromFormGroup();
    const newDefaultTopicSows = formGroupValue.defaultTopicSows;
    this.learningObjectiveService
      .update(newTopic, this.learningObjective.id)
      .pipe(
        delay(1000),
        switchMap(res => {
          return this.updateCurriculumLearningObjectiveSow.update(
            {
              sow_custom_fields: newDefaultTopicSows,
              duration: formGroupValue.topic.duration,
              sequence_num: formGroupValue.topic.sequence_num,
              title: formGroupValue.topic.title
            } as any,
            this.learningObjective.id
          );
        })
      )
      .subscribe(res => {
        if (!this.subject.defaultTopicSows) {
          this.subject.defaultTopicSows = [];
        }
        this.subject.defaultTopicSows.push(...newDefaultTopicSows);
        this.topicUpdated.emit(newTopic);
      });
  }

  topicNameChanged(val: string | number) {
    /* tslint:disable:no-string-literal */
    if (val === this.form.controls['title'].value) {
      return;
    }
    this.form.controls['title'].patchValue(val);
    /* tslint:disable:no-string-literal */
    this.httpClient
      .get(
        `/curriculumLearningObjectives/find?subject=${ this.subject.subject }&curriculumIds=${ this.curriculumIds }&searchPhrase=${ val }`
      )
      .subscribe((resp: any) => {
        this.masterTopicOptions = [];
        this.masterTopicOptions = this.getMasterTopicOptions(resp.objectList || []);
      });
  }

  addNewUnit() {
    const newUnitName = this.unitTopicFormGroup.controls.unit.value;
    const newTopicName = this.unitTopicFormGroup.controls.topic.value;
    let newUnitId;
    let newTopicId;
    this.unitService
      .add({
        curriculum_paper_id: this.paperResponse.paper.id,
        is_published: false,
        ref_num: 1,
        title: newUnitName
      })
      .pipe(
        switchMap(res => {
          newUnitId = res.id;
          return this.createNewTopic(newTopicName, newUnitId);
        }),
        switchMap(res => {
          newTopicId = res.id;
          return of({ unitId: newUnitId, topicId: newTopicId });
        })
      )
      .subscribe(this.createNewUnitTopicCallback);
  }

  addNewTopic() {
    const newTopicName = this.unitTopicFormGroup.controls.topic.value;
    this.createNewTopic(newTopicName, this.unit.id)
      .pipe(
        switchMap(res => {
          return of({ unitId: this.unit.id, topicId: res.id });
        })
      )
      .subscribe(this.createNewUnitTopicCallback);
  }

  getCustomFieldsToDisplay(): KeyValue[] {
    return this.customFieldsToDisplay.filter(field => (this.disabledStatus && !field.value ? field.value : true));
  }
}
