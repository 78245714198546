<!--
 Created by Yaroslav S. on 10/07/2024
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->

<div class="open-ai-section">
  <div class="open-ai-section-title">Open AI</div>

  <div class="form-fields">
    <div class="form-fields-label">Secret Key</div>
    <curr-input
      [formGroup]="form"
      controlName="openaiApiKey"
      class="full-width form-field"
      [errorMessage]="form.controls['openaiApiKey'].hasError('required') ? 'Secret Key is required' : ''"
    ></curr-input>
  </div>

  <curr-credits-error *ngIf="showError"></curr-credits-error>

  <div class="open-ai-section-subtitle">Pricing for every 750 words or 1K tokens</div>

  <div class="open-ai-section-table">
    <div class="open-ai-section-table-row">
      <div class="row-item row-item-title">Model</div>
      <div class="row-item row-item-title">Input</div>
      <div class="row-item row-item-title">Model</div>
    </div>
    <div class="open-ai-section-table-row">
      <div class="row-item">GPT-3.5 Turbo</div>
      <div class="row-item">$0,0005</div>
      <div class="row-item">$0,0015</div>
    </div>
    <div class="open-ai-section-table-row">
      <div class="row-item">GPT-4</div>
      <div class="row-item">$0,03</div>
      <div class="row-item">$0,06</div>
    </div>
    <div class="open-ai-section-table-row">
      <div class="row-item">GPT-4 Turbo</div>
      <div class="row-item">$0,01</div>
      <div class="row-item">$0,03</div>
    </div>
  </div>

  <div class="open-ai-section-table-mobile">
    <div class="open-ai-section-table-row">
      <div class="row-item row-item-title">Model</div>
      <div class="row-item">GPT-3.5 Turbo</div>
    </div>
    <div class="open-ai-section-table-row">
      <div class="row-item row-item-title">Input</div>
      <div class="row-item">$0,0005</div>
    </div>
    <div class="open-ai-section-table-row last-row">
      <div class="row-item row-item-title">Model</div>
      <div class="row-item">$0,0015</div>
    </div>

    <div class="open-ai-section-table-row">
      <div class="row-item row-item-title">Model</div>
      <div class="row-item">GPT-4</div>
    </div>
    <div class="open-ai-section-table-row">
      <div class="row-item row-item-title">Input</div>
      <div class="row-item">$0,03</div>
    </div>
    <div class="open-ai-section-table-row last-row">
      <div class="row-item row-item-title">Model</div>
      <div class="row-item">$0,06</div>
    </div>

    <div class="open-ai-section-table-row">
      <div class="row-item row-item-title">Model</div>
      <div class="row-item">GPT-4 Turbo</div>
    </div>
    <div class="open-ai-section-table-row">
      <div class="row-item row-item-title">Input</div>
      <div class="row-item">$0,01</div>
    </div>
    <div class="open-ai-section-table-row">
      <div class="row-item row-item-title">Model</div>
      <div class="row-item">$0,03</div>
    </div>
  </div>

  <div class="open-ai-section-note m-b-0">
    Note! Prices are for reference only and are not updated in real time. Please check the Pricing section on openai.com
    for the most accurate pricing.
  </div>

  <div class="save-actions">
    <curr-button
      variant="contained"
      [isDisabled]="form.invalid || !openaiApiKey.dirty"
      (btnClick)="saveApiKey()"
      size="big"
      >SAVE</curr-button
    >
  </div>
</div>
