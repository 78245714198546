/**
 * Created by Maxim Bon. on 12/05/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CumulativeTest } from './cumulative-test';
import { Question, QuestionsLabels, QuestionType } from '../../student/test.model';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmPopupComponent } from '../../shared/components/confirm-popup/confirm-popup.component';
import { SaveTestPopupComponent } from './save-test-popup/save-test-popup.component';
import { EditTestNamePopupComponent } from './edit-test-name-popup/edit-test-name-popup.component';
import { ConfirmTopicsPopupComponent } from './confirm-topics-popup/confirm-topics-popup.component';
import { QuestionDatabasePopupComponent } from '../../shared/components/question-database-popup/question-database-popup.component';
import { getRestApiProvider, RestApiService, restApiServiceCreator } from '../../shared/api/rest-api.service';
import { ApplicationHttpClient } from '../../shared/api/application-http-client';
import { COUResponse, PaperResponse } from '../subject-details/api-subject-details';
import { TestCreationService } from './test-creation.service';
import { ToastrService } from 'ngx-toastr';
import { BreadcrumbService } from 'src/app/shared/components/breadcrumb/breadcrumb.service';
import { forkJoin, Observable, Subject, timer } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { FileUploadService } from '../../shared/components/edit-question-block/file-upload.service';
import { CalendarComponent } from '../../shared/components/calendar/calendar.component';
import { fadeAnimation } from '../../shared/animations/fade.animation';
import * as moment from 'moment';
import { MatRadioButton } from '@angular/material/radio';
import { ETestType } from '../../teacher/teacher.enum';
import { FullScreenPopupService } from '../../shared/components/full-screen-popup/full-screen-popup.service';
import { CreateEditDialogComponent } from 'src/app/shared/components/new-question-block/create-edit-dialog/create-edit-dialog.component';

interface SubjectTestSelectTopic {
  id: number;
  title: string;
  alreadyUsed: boolean;
  is_passed: boolean;
}

interface FilterListItem {
  label: string;
  value: string | number;
}

@Component({
  selector: 'curr-edit-test',
  templateUrl: './edit-test.component.html',
  styleUrls: ['./edit-test.component.scss'],
  providers: [getRestApiProvider<COUResponse>('curriculumCoUQuestions'), BreadcrumbService, FullScreenPopupService],
  animations: [fadeAnimation],
})
export class EditTestComponent implements OnInit, OnDestroy {
  @ViewChild('calendar') calendarRef: CalendarComponent;

  updated: boolean = false;
  test: CumulativeTest;
  paperTopics: SubjectTestSelectTopic[];
  cTQuestionsService: RestApiService<Question>;
  cumulativeTestService: RestApiService<Question>;
  ddOptionsGrade = [];
  isTestHasTopics = false;
  isOffline = false;
  lastSelectedQuestionIndex = 0;
  schoolYearCourseId = 0;
  newSchoolYearCourseId = 0;
  paperId = 0;
  selectedQuestion: Question;
  cTQuestions: Question[] = [];
  cTQuestionsFiltered: Question[] = [];
  cTQuestionsFilteredMarkType: 'MIXED' | 'AI_MARKED' | 'SELF_MARKED';
  cou: COUResponse[] = [];
  paper: PaperResponse;
  path = [];
  questionType = QuestionType;
  private unsubscribe$: Subject<void> = new Subject<void>();
  isPublishClicked = false;
  isImagesLoading = false;
  isTestNameEntered = false;
  isSelectedAllTopics = false;
  testTypeParam = '';
  isLoading = false;

  startDate = '';
  startTime = '';
  dueDate = '';
  dueTime = '';

  oldStartDate: any;
  oldStartTime: any;
  oldDueDate: any;
  oldDueTime: any;

  ddFilteredOptionsTopic = [];
  ddFilteredOptionsTopicClear = [];

  // FILTERS - DROPDOWN
  ddOptionsTopic: FilterListItem[] = [];
  ddOptionsTopicWithoutFirst: FilterListItem[] = [];
  ddOptionsQuestionTypes: FilterListItem[] = [];
  filtersData: { questionType: QuestionType | null; questionTopicId: number | null; isPast: boolean | null } = {
    questionType: null,
    questionTopicId: null,
    isPast: null,
  };

  markTypeItem: string = 'SELF_MARKED';
  isDisabledActionsBtn = false;

  constructor(
    private route: ActivatedRoute,
    private tcService: TestCreationService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private httpClient: ApplicationHttpClient,
    private fileUploadService: FileUploadService,
    private couService: RestApiService<COUResponse>,
    private breadcrumbService: BreadcrumbService,
    private router: Router,
    public fullScreenPopupService: FullScreenPopupService,
  ) {
    this.initOptionsForFilters();
  }

  ngOnInit(): void {
    this.ddOptionsQuestionTypes = [
      { value: null, label: 'All Question Types' },
      { value: QuestionType.OPEN_ENDED, label: QuestionsLabels.OPEN_ENDED },
      { value: QuestionType.SELECT_ONE, label: QuestionsLabels.MULTI_CHOICE },
      { value: QuestionType.SINGLE_ANSWER, label: QuestionsLabels.SINGLE_ANSWER },
      { value: QuestionType.TRUE_OR_FALSE, label: QuestionsLabels.TRUE_FALSE },
      { value: QuestionType.FILE_INPUT, label: QuestionsLabels.FILE },
      { value: QuestionType.MULTIPART, label: QuestionsLabels.MULTI_LEVEL },
    ];

    this.cTQuestionsService = restApiServiceCreator<Question>('curriculumLearningObjectiveCTQuestions')(
      this.httpClient,
    );

    this.test = this.route.snapshot.data.subjectTest.ct;
    this.newSchoolYearCourseId = this.route.snapshot.data.subjectTest.schoolYearCourseId;
    this.paperId = this.route.snapshot.data.subjectTest.paperId;
    this.paperTopics = this.route.snapshot.data.subjectTopics;
    this.ddOptionsTopic = this.ddOptionsTopicWithoutFirst = this.paperTopics.map((item) => {
      return { value: item.id, label: item.title.replace(/<[^>]*>/g, '') };
    });
    this.ddOptionsTopic = [{ value: null, label: 'All Topics' }, ...this.ddOptionsTopic];

    this.isTestHasTopics = !!this.test?.lobjective_ids?.length;

    this.test.lobjective_ids = this.paperTopics?.filter((topic) => topic.is_passed).map((topic) => topic.id);

    if (this.test.id) {
      this.getQuestions();
    }
    this.getCou();
    if (!this.test.id) {
      this.route.params.pipe(take(1)).subscribe((res) => {
        this.schoolYearCourseId = res?.schoolYearCourseId;
        this.test.school_year_course_id = this.schoolYearCourseId;
      });
    }
    if (!this.paperTopics.length) {
      this.updated = true;
    }

    this.testTypeParam = this.route.snapshot.paramMap.get('testType');
    this.test.type = ETestType.CUMULATIVE_TEST;
    if (this.testTypeParam === 'homework') {
      this.test.type = ETestType.HOMEWORK;
    }
    if (this.testTypeParam === 'quiz') {
      this.test.type = ETestType.QUIZ;
    }

    this.initBreadcrumbs();

    this.fullScreenPopupService.fullScreenPopupCounter.pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {
      this.isDisabledActionsBtn = res > 0;
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initBreadcrumbs() {
    let lastLabel = 'Cumulative Test';
    if (this.router.url.includes('homework')) {
      lastLabel = 'Homework';
    }
    if (this.router.url.includes('quiz')) {
      lastLabel = 'Quiz';
    }

    this.breadcrumbService
      .getRoutParameter('id', true)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (param: string[]) => {
          this.path = [
            { label: 'Home', url: '/dashboard' },
            {
              label: 'Courses',
              url: '/subjects',
            },
            {
              label: lastLabel,
              url: `/courses/${this.testTypeParam}/${this.newSchoolYearCourseId}/${this.paperId}`,
            },
            { label: 'Create New', url: '' },
          ];
        },
      });
  }

  private getCou() {
    this.httpClient
      .get(`/curriculumPapers/${this.test.curriculum_paper_id}`)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((resp) => {
        this.paper = (resp as any).data;
        if (this.paper.paper.curriculum_id) {
          this.couService
            .getAll({ curriculum_id: this.paper.paper.curriculum_id })
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((r) => {
              this.cou = r;
            });
        }
      });
  }

  private getQuestions() {
    this.httpClient
      .get(`/curriculumCumTests/getCumulativeTestQuestions?curriculum_cum_test_id=${this.test.id}`)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((resp) => {
        this.cTQuestions = (resp as any).data.items;
        if (this.cTQuestions.length) {
          this.cTQuestions.forEach((q) => {
            if (q.question.includes('<img src')) {
              this.getQuestionDescription(q);
              this.setImgWidth(q);
            }
          });
          this.selectQuestion(this.cTQuestions[0], 0);
        }

        this.cTQuestionsFiltered = this.cTQuestions;
        this.checkMarkType();
      });
  }

  private checkMarkType() {
    const aiMarkedAmount = this.cTQuestionsFiltered.find((item) => item.markType === 'AI_MARKED');
    const selfMarkedAmount = this.cTQuestionsFiltered.find((item) => item.markType === 'SELF_MARKED');

    if (aiMarkedAmount) this.cTQuestionsFilteredMarkType = 'AI_MARKED';
    if (selfMarkedAmount) this.cTQuestionsFilteredMarkType = 'SELF_MARKED';
    if (aiMarkedAmount && selfMarkedAmount) this.cTQuestionsFilteredMarkType = 'MIXED';

    this.markTypeItem = this.cTQuestionsFilteredMarkType;
  }

  initOptionsForFilters() {
    this.ddOptionsGrade.push({ value: 'all', label: `All Years` });
    for (let i = 1; i <= 12; i++) {
      this.ddOptionsGrade.push({ value: i, label: `Year ${i}` });
    }
  }

  cancelTest() {
    this.router.navigate([
      'courses',
      this.testTypeParam,
      this.test.school_year_course_id,
      this.paper.paper.curriculum_id,
    ]);

    // if (this.test.school_year_course_id) {
    //   this.router.navigate([
    //     `subjectsUpdate/${ this.paper.paper.curriculum_id }/${ this.test.school_year_course_id }/paper/${ this.paper.paper.id }`
    //   ]);
    // } else {
    //   this.router.navigate(['subjectsUpdate', this.paper.paper.curriculum_id, 'paper', this.paper.paper.id]);
    // }
  }

  canSaveTest() {
    if (this.isOffline) {
      return !this.test?.test_name || !this.test.lobjective_ids.length || !this.test.curriculum_paper_id;
    }
    return (
      !this.test?.test_name ||
      !this.test?.lobjective_ids?.length ||
      !this.test?.lobjective_cum_question_ids?.length ||
      !this.test?.curriculum_paper_id
    );
  }

  saveTestAsDraft() {
    this.test.is_published = false;
    this.saveTest();
  }

  saveTest() {
    this.isPublishClicked = true;

    if (!this.canSaveTest() && this.isPublishClicked) {
      if (!this.startDate || this.startDate === 'Invalid date') {
        this.toastr.error('Please select the Start Date first');
        return;
      }

      if (!this.startTime || this.startTime === 'Invalid date') {
        this.toastr.error('Please select the Start Time first');
        return;
      }

      if (!this.dueTime || this.dueTime === 'Invalid date') {
        this.toastr.error('Please select the Due Time first');
        return;
      }

      if (!this.dueDate || this.dueDate === 'Invalid date') {
        this.toastr.error('Please select the Due Date first');
        return;
      }

      if (!this.test.start_time || this.test.start_time === 'Invalid date') {
        this.toastr.error('Please select the Start Date first');
        return;
      }

      if (!this.test.end_time || this.test.end_time === 'Invalid date') {
        this.toastr.error('Please select the Due Date first');
        return;
      }

      if (this.test.end_time < this.test.start_time) {
        this.toastr.error('Due Date should be greater then Start Date');
        return;
      }

      if (!this.test.start_time && !this.isTestNameEntered) {
        this.toastr.error('Please enter the Test Name');
        return;
      }
    }

    if (!this.checkIsTestNameEntered()) {
      return;
    }
    // if (typeof this.test.start_time !== 'string') {
    //   this.test.start_time = this.test.start_time
    //     .toISOString()
    //     .replace(/T/g, ' ')
    //     .replace(/Z/g, ' ');
    // }
    const dialogRef = this.dialog.open(SaveTestPopupComponent, {
      disableClose: true,
      width: '424px',
      data: {
        test: this.test,
        testTypeParam: this.testTypeParam,
        paperId: this.route.snapshot.params.paperId,
        testId: this.route.snapshot.params.testId,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result === 'cancel') {
          return;
        }
        this.test = result;
      });
  }

  changeSelection(e: any, type: string) {
    this.test.sequence_num = e;
  }

  addToTestTopics(t: SubjectTestSelectTopic) {
    const ind = this.test?.lobjective_ids.findIndex((topic) => topic === t.id);
    if (ind === -1) {
      this.test?.lobjective_ids.push(t.id);
      t.is_passed = true;
      this.isSelectedAllTopics = this.paperTopics.length === this.test?.lobjective_ids.length;
    } else {
      this.test?.lobjective_ids.splice(ind, 1);
      t.is_passed = false;
      this.isSelectedAllTopics = false;
    }

    const filterTopics = this.paperTopics.filter((item) => this.test?.lobjective_ids.includes(item.id));

    this.ddFilteredOptionsTopicClear = this.ddFilteredOptionsTopic = filterTopics.map((item) => {
      return { value: item.id, label: item.title.replace(/<[^>]*>/g, '') };
    });
    this.ddFilteredOptionsTopic = [{ value: null, label: 'All Topics' }, ...this.ddFilteredOptionsTopic];
  }

  addToTestTopicsAll(checked: boolean) {
    this.isSelectedAllTopics = checked;

    if (this.test?.lobjective_ids.length > 0) {
      this.test.lobjective_ids = [];
      this.paperTopics.map((item) => {
        item.is_passed = false;
      });
    }

    if (checked) {
      this.paperTopics.map((item) => {
        this.test?.lobjective_ids.push(item.id);
        item.is_passed = true;
      });
    }

    const filterTopics = this.paperTopics.filter((item) => this.test?.lobjective_ids.includes(item.id));

    this.ddFilteredOptionsTopicClear = this.ddFilteredOptionsTopic = filterTopics.map((item) => {
      return { value: item.id, label: item.title.replace(/<[^>]*>/g, '') };
    });
    this.ddFilteredOptionsTopic = [{ value: null, label: 'All Topics' }, ...this.ddFilteredOptionsTopic];
  }

  isTopicSelected(t: { id: number; name: string; alreadyUsed: boolean }) {
    return this.test?.lobjective_ids?.findIndex((topic) => topic === t.id) > -1;
  }

  addTopics() {
    const firstTime = !this.test?.lobjective_cum_question_ids.length;
    const msg = this.test?.lobjective_cum_question_ids.length
      ? 'You updated covered topics, do we need to update the question?'
      : '';
    const title = this.test?.lobjective_cum_question_ids.length ? 'Update questions' : 'Test format';
    const dialogRef = this.dialog.open(ConfirmTopicsPopupComponent, {
      width: '300px',
      data: {
        msg,
        title,
        firstTime,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        this.isTestHasTopics = true;
        // @ts-ignore
        // this.test?.topic_cum_question_ids = [];

        if (result === 'offline') {
          this.isOffline = true;
          this.test.is_offline = true;
        }
        if (result === 'new') {
          this.oldStartTime = null;
          this.oldStartDate = null;
          this.oldDueTime = null;
          this.oldDueDate = null;
          this.startDate = null;
          this.dueDate = null;
          this.generateTestQuestions();
        }
        if (result === 'manually') {
        }
        if (result === 'db') {
          this.generateTestQuestions();
        }
      });
  }

  generateTestQuestions() {
    this.tcService
      .generateTestQuestions(this.test.lobjective_ids, this.test.type)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp) => {
          const r = resp as any;
          if (r.data) {
            this.cTQuestions = r.data.items;
            this.cTQuestions.forEach((q) => {
              if (q.question.includes('<img src')) {
                this.getQuestionDescription(q);
              }
            });
            this.cTQuestionsFiltered = this.cTQuestions;
            this.checkMarkType();
          }
          this.selectFirstQuestionAndAddQuestionIdsToTest();
        },
        (err) => {
          this.toastr.warning('An error happened, please try later');
        },
        () => {
          if (!this.cTQuestions.length) {
            this.toastr.info('We found 0 questions for this CT:(');
          } else {
            this.toastr.success(`We found ${this.cTQuestions.length} for this CT !`);
          }
        },
      );
  }

  openDataBaseQuestionPopup() {
    const dialogRef = this.dialog.open(QuestionDatabasePopupComponent, {
      width: '80%',
      data: {
        questions: this.cTQuestions,
        topicIds: this.test.lobjective_ids,
        isCTCreation: true,
        questionType: this.test.type,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result.addManually) {
          this.addQuestionManually();
        }
        if (result.length) {
          result.forEach((i) => {
            if (!this.cTQuestions.find((q) => q.id === i.id)) {
              this.cTQuestions.push(i);
            }
          });
        }
        this.selectFirstQuestionAndAddQuestionIdsToTest();
      });
  }

  private selectFirstQuestionAndAddQuestionIdsToTest() {
    if (this.cTQuestions.length) {
      // @ts-ignore
      this.cTQuestions[0].isSelected = true;
      this.selectedQuestion = this.cTQuestions[0];
      this.lastSelectedQuestionIndex = 0;
    }
    this.test.lobjective_cum_question_ids = this.cTQuestions.map((i) => i.id);
  }

  selectQuestion(q: any, i: number) {
    // @ts-ignore
    this.cTQuestions[this.lastSelectedQuestionIndex]?.isSelected = false;
    // @ts-ignore
    q?.isSelected = true;
    this.selectedQuestion = q;
    this.lastSelectedQuestionIndex = i;
  }

  deleteQuestion($event: MouseEvent, i: number) {
    $event.stopPropagation();
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      width: '384px',
      data: {
        questionTitle: 'Are you sure in deleting?',
        confirmLabel: 'YES',
        cancelLabel: 'NO',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result) {
          this.cTQuestionsFiltered.splice(i, 1);
          this.test.lobjective_cum_question_ids.splice(i, 1);
          this.checkMarkType();
          if (this.cTQuestionsFiltered.length > 0) this.selectQuestion(this.cTQuestionsFiltered[0], 0);
        }
      });
  }

  addQuestionManually() {
    const ref = this.fullScreenPopupService.open(CreateEditDialogComponent, {
      options: {
        dialogTitle: 'Create Manually',
      },
      data: {
        closeDialogTitle: 'Close Manual Creation?',
        dialogType: 'manual',
        dialogMode: 'create',
        dialogSubTitle: 'Add Question',
        topicList: this.ddFilteredOptionsTopicClear,
        selectedTopicInFilter: this.filtersData.questionTopicId,
        paperTopics: this.paperTopics,
      },
    });

    ref.instance.onClose.pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {
      if (res?.data?.questions.length > 0) {
        const ids = res.data.questionsIds;

        if (ids.length > 0) {
          ids.forEach(questionId => {
            this.test?.lobjective_cum_question_ids.push(questionId);
          });
        }

        const questionsList = res.data.questions.map((question, i) => {
          question.id = ids[i] ?? null;
          return question;
        })

        questionsList.forEach(question => {
          this.cTQuestionsFiltered.push(question);
        });
        
        const len = this.cTQuestions.length;
        this.selectedQuestion = this.cTQuestions[len - 1];
        this.selectQuestion(this.cTQuestions[len - 1], len - 1);
        this.checkMarkType();
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });

        this.toastr.success('The question was created successfully');
      }
    });
  }

  editQuestion(question: Question) {
    const ref = this.fullScreenPopupService.open(CreateEditDialogComponent, {
      options: {
        dialogTitle: 'Edit Manually',
      },
      data: {
        closeDialogTitle: 'Close Edit Question?',
        dialogSubTitle: 'Edit Question',
        dialogType: 'manual',
        dialogMode: 'edit',
        topicList: this.ddFilteredOptionsTopicClear,
        selectedTopicInFilter: this.filtersData.questionTopicId,
        paperTopics: this.paperTopics,
        question,
      },
    });

    ref.instance.onClose.pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {
      if (res?.data?.questions && res.data.questions.length > 0) {
        this.cTQuestionsFiltered = this.cTQuestionsFiltered.map(question => {
          if (question.id === res.data.questions[0].id) {
            question = res.data.questions[0];
          }

          return question;
        });

        this.selectedQuestion = res.data.questions[0];

        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });

        this.toastr.success('The question was updated successfully');
      }
    });
  }

  addQuestionWithAIAssistant() {
    const ref = this.fullScreenPopupService.open(CreateEditDialogComponent, {
      options: {
        dialogTitle: 'AI Assistant',
        isFullScreen: true,
        showFullScreenBtn: false,
      },
      data: {
        closeDialogTitle: 'Close AI Assistant?',
        dialogSubTitle: 'Add Question',
        dialogMode: 'create',
        dialogType: 'ai',
        topicList: this.ddFilteredOptionsTopicClear,
        curriculumId: this.paper.paper.curriculum_id,
      },
    });

    ref.instance.onClose.pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {
      if (res?.data?.questions.length > 0) {
        const ids = res.data.questionsIds;

        if (ids.length > 0) {
          ids.forEach(questionId => {
            this.test?.lobjective_cum_question_ids.push(questionId);
          });
        }

        const questionsList = res.data.questions.map((question, i) => {
          question.id = ids[i] ?? null;
          return question;
        })

        questionsList.forEach(question => {
          this.cTQuestionsFiltered.push(question);
        });

        const len = this.cTQuestions.length;
        this.selectedQuestion = this.cTQuestions[len - 1];
        this.selectQuestion(this.cTQuestions[len - 1], len - 1);
        this.checkMarkType();
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });

        this.toastr.success('The question was created successfully');
      }
    });
  }

  changeMarkType(question: Question) {
    this.cumulativeTestService = restApiServiceCreator<Question>('curriculumLearningObjectiveCTQuestions')(
      this.httpClient,
    );

    this.cumulativeTestService
      .update(question, question.id + '')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((resp) => {
        this.checkMarkType();
        this.toastr.success('Question was updated successfully');
      });
  }

  topicCheckBoxClick($event: MouseEvent, t: SubjectTestSelectTopic) {
    $event.stopPropagation();
    $event.preventDefault();
    this.addToTestTopics(t);
  }

  setStartTime(time: any) {
    if (time) {
      this.oldStartTime = time;
      this.startTime = time;
      this.checkFullDate();
    }
  }

  setStartDate(date: any) {
    console.log(date)
    if (date) {
      this.oldStartDate = date.value;
      this.startDate = date ? moment(date.value).format('YYYY-MM-DD') : '';
      this.checkFullDate();
    }
  }

  setDueTime(time: any) {
    if (time) {
      this.oldDueTime = time;
      this.dueTime = time;
      this.checkFullDate();
    }
  }

  setDueDate(date: any) {
    if (date) {
      this.oldDueDate = date.value;
      this.dueDate = date ? moment(date.value).format('YYYY-MM-DD') : '';
      this.checkFullDate();
    }
  }

  checkFullDate() {
    console.log('checkFullDate:: startDate', this.startDate)
    if (this.startTime && this.startTime !== '' && this.startDate && this.startDate !== '') {
      this.test.start_time = moment(`${this.startDate} ${this.startTime}`).format('YYYY-MM-DD HH:mm:ss');
    } else {
      this.test.start_time = null;
    }

    console.log('checkFullDate:: dueDate', this.dueDate)
    if (this.dueTime && this.dueTime !== '' && this.dueDate && this.dueDate !== '') {
      this.updated = true;
      this.test.end_time = moment(`${this.dueDate} ${this.dueTime}`).format('YYYY-MM-DD HH:mm:ss');
    } else {
      this.updated = false;
      this.test.end_time = null;
    }
  }

  editName() {
    const dialogRef = this.dialog.open(EditTestNamePopupComponent, {
      width: '424px',
      data: {
        name: this.test?.test_name,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result) {
          this.isTestNameEntered = true;
          this.test.test_name = result;
        }
      });
  }

  editTopics() {
    this.isTestHasTopics = false;
    this.test?.lobjective_ids.forEach((t) => {
      const topic = this.paperTopics.find((st) => st.id === t);
      if (topic) {
        topic.is_passed = true;
      }
    });
  }

  createOnlineTest() {
    this.isOffline = false;
    this.test.is_offline = false;
  }

  getRemainedTestTime() {
    if (!this.cTQuestions.length) {
      return 60;
    } else {
      const time =
        60 -
        this.cTQuestions.reduce((curr, next) => {
          return curr + +next.estimated_time;
        }, 0);
      return time > 0 ? time : 0;
    }
  }

  getQuestionDescription(question: Question) {
    if (question.question.includes('<img src')) {
      const imageKeyValue = this.fileUploadService.getAllImageKeysFromNote(question.question);
      this.uploadImages(imageKeyValue, question);
    }
    return question.question;
  }

  setQuestionsMarkType(questionsMarkType: { source: MatRadioButton; value: 'AI_MARKED' | 'SELF_MARKED' }) {
    this.isLoading = true;
    this.test.markType = questionsMarkType.value;

    const ctQuestionsIds = [];
    this.cTQuestionsFiltered.forEach(firstLevel => {
      ctQuestionsIds.push(firstLevel.id);
      
      if (firstLevel.children.length > 0) {
        firstLevel.children.forEach(secondLevel => {
          ctQuestionsIds.push(secondLevel.id);

          if (secondLevel.children.length > 0) {
            secondLevel.children.forEach(thirdLevel => {
              ctQuestionsIds.push(thirdLevel.id);
            });
          }
        });
      }
    });

    this.tcService
      .updateCumulativeTestEstimationType(ctQuestionsIds, this.test.markType)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.cTQuestionsFiltered = this.cTQuestionsFiltered.map((item) => {
            if (item.children.length > 0) {
              item.children = item.children.map(secondLevel => {
                if (secondLevel.children.length > 0) {
                  secondLevel.children = secondLevel.children.map(thirdLevel => {
                    thirdLevel.markType = this.test.markType;
                    return thirdLevel;
                  })
                }

                secondLevel.markType = this.test.markType;
                return secondLevel;
              })
            }

            item.markType = this.test.markType;
            return item;
          });
          this.checkMarkType();
          this.isLoading = false;
        },
        (error) => (this.isLoading = false),
      );
  }

  changeTopicFilter(questionTopicId: number) {
    this.filtersData.questionTopicId = questionTopicId;
    this.filterList();
  }

  changeQuestionTypesFilter(type: QuestionType) {
    this.filtersData.questionType = type;
    this.filterList();
  }

  setPastPaper(isPast: boolean) {
    this.filtersData.isPast = isPast ? true : null;
    this.filterList();
  }

  private filterList() {
    this.cTQuestionsFiltered = this.cTQuestions.filter((item) => {
      return (
        (this.filtersData.questionTopicId ? item.curriculum_lobj_id === this.filtersData.questionTopicId : true) &&
        (this.filtersData.questionType ? item.answer_type === this.filtersData.questionType : true) &&
        (this.filtersData.isPast ? item.is_past_exam_question === this.filtersData.isPast : true)
      );
    });

    // const len = this.cTQuestionsFiltered.length;
    // this.selectedQuestion = this.cTQuestionsFiltered[len - 1];
    // this.selectQuestion(this.cTQuestionsFiltered[len - 1], len - 1);

    this.checkMarkType();
  }

  private async uploadImages(imageKeyValue: any, q: Question) {
    this.isImagesLoading = true;
    let previewQuestion = q.question;

    const observersObj: { [key: string]: Observable<any> } = {};

    for (const key of Object.keys(imageKeyValue)) {
      observersObj[key] = this.fileUploadService.download(key, 'IMAGE');
    }

    forkJoin(observersObj).subscribe({
      next: (res: any) => {
        for (const [key, value] of Object.entries(res)) {
          const bytes = (value as any)?.data?.bytes;
          imageKeyValue[key] = 'data:image/png;base64,' + bytes;
          previewQuestion = previewQuestion.replace(key, imageKeyValue[key]);
        }
      },
      complete: () => {
        this.isImagesLoading = false;
        q.previewQuestion = previewQuestion;
        this.setImgWidth(q);
      },
    });
  }

  private setImgWidth(q: Question) {
    timer(0)
      .pipe(take(1))
      .subscribe((resp) => {
        const root = document.getElementById(`desc-${q.id}`);
        const allImgs = root?.querySelectorAll('img');
        const rw = root?.offsetWidth;
        if (allImgs.length) {
          allImgs.forEach((e) => {
            if (e.width > rw) {
              // For mobile, decrease width
              e.setAttribute('width', '100%');
            }
          });
        }
      });
  }

  private checkIsTestNameEntered(): boolean {
    if (!this.isTestNameEntered) {
      this.editName();
      return false;
    }
    return true;
  }
}
