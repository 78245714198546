<!--
 Created by Dmytro Sav. on 4/28/2020
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->

<div class="curr-answer" *ngIf="question">
  <curr-expansion-panel title="{{ answerData?.student?.firstName + ' ' + answerData?.student?.lastName }}" status="{{ answersForApprove.reviewState && answersForApprove.reviewState === 'APPROVED' ? 'approved' : 'pending' }}">
    <div class="panel-content">
      <div class="panel-content-item">
        <div class="item-main-title">Data From Student</div>
        <ng-container *ngIf="question.answerType === qt.OPEN_ENDED">
<!--          <div class="item-title">Description answer</div>-->
<!--          <div class="item-content" *ngIf="!isDescriptionImage" [currMath]="studentAnswer.answerSolution || 'No data'"></div>-->
<!--          <div class="item-content" *ngIf="isDescriptionImage" [currMath]="image"></div>-->
          <div class="item-title">Answer</div>
          <div class="item-content {{ studentAnswer.isCorrect ? 'correct-answer' : 'wrong-answer'}}" *ngIf="!isResultImage && studentAnswerText" [currMath]="studentAnswerText || 'No data'"></div>
          <div class="item-content {{ studentAnswer.isCorrect ? 'correct-answer' : 'wrong-answer'}}" *ngIf="isResultImage" [currMath]="imageResultAnswer"></div>
        </ng-container>

        <ng-container *ngIf="question.answerType === qt.SINGLE_ANSWER">
          <div class="item-title">Answer</div>
          <div class="item-content" *ngIf="!isImage" [currMath]="studentAnswer.studentAnswerText"></div>
          <div class="item-content" *ngIf="isImage" [currMath]="image"></div>
        </ng-container>

        <ng-container *ngIf="question.answerType === qt.FILE_INPUT">
          <div class="item-title">Answer</div>
          <div class="item-content" [currMath]="studentAnswer.studentAnswerText"></div>
          <ng-container *ngIf="studentAnswer.studentAnswerFile && isFileTypeOfImage();">
            <curr-loader [isLoading]="isFileLoading" [diameter]="48"></curr-loader>

            <div class="wrapper-image full-width m-b-8" *ngIf="imagePreviewFile"
                 (click)="openImagePreviewDialog(imagePreviewFile)">
              <div class="hover-container"></div>
              <mat-icon class="zoom-icon" svgIcon="zoom-icon"></mat-icon>
              <img [src]="imagePreviewFile" alt="Image answer" />
            </div>
          </ng-container>
          <div class="m-b-8 flex" *ngIf="imagePreviewFile && isFileTypeOfImage()">
            <a class="download-file" [href]="imagePreviewFile" download="file" target="_blank">
              <curr-button variant="outlined" [icon]="'download'" [style]="fileInputBtnStyle">
                Download file
              </curr-button>
            </a>
          </div>

          <div class="m-b-8 flex" *ngIf="!isFileTypeOfImage()">
            <a class="download-file" [href]="previewFile" [download]="'file.'+fileExtension" target="_blank">
              <curr-button variant="outlined" [icon]="'download'" [style]="fileInputBtnStyle">
                Download file
              </curr-button>
            </a>
          </div>
        </ng-container>

        <ng-container *ngIf="question.answerType === qt.SELECT_ONE
                        || question.answerType === qt.SELECT_MULTIPLE
                        || question.answerType === qt.TRUE_OR_FALSE">
          <div class="item-title">Answer</div>
          <ng-container *ngIf="studentAnswer?.studentCorrectAnswerOptions?.length; else emptyAnswerList">
            <div *ngFor="let a of studentAnswer?.studentCorrectAnswerOptions; let i = index; let last = last"
                 class="ls mat-body-1 reset">
              <div fxLayout="row" class="m-b-8 answer-option" [class.reset]="last"
                   [class.correct]="isStudentAnswerCorrect(a)">
                <mat-icon svgIcon="circle" class="circle m-r-8"></mat-icon>
                <span *ngIf="!isImage" [currMath]="answerData.answers[a]"></span>
                <span *ngIf="isImage" [currMath]="image"></span>
              </div>
            </div>
          </ng-container>
          <ng-template #emptyAnswerList>No answer</ng-template>
        </ng-container>

        <div class="cou m-t-8" *ngIf="studentAnswer.studentCou">
          <div class="m-b-8 mat-h4">Confirmation of understanding</div>
          <div class="answer" [currMath]="studentAnswer.studentCou"></div>
        </div>
      </div>

      <div class="panel-content-item">
        <div class="item-main-title" *ngIf="isShowSelfMarkingTitle()">Student self marking</div>
        <div class="item-main-title" *ngIf="isShowAICommentsTitle()">AI Comments</div>

        <div class="self-mark-data" *ngIf="(!question.markType || question.markType === 'SELF_MARKED') && studentAnswer.selfMark">
          <div class="self-mark-data-row" *ngIf="answerSelfMarkSchemas.length > 0">
            <div class="row-title">Marks</div>
            <div class="row-content">
              <div class="open-ended-answers">
                <ng-container *ngFor="let selfMark of answerSelfMarkSchemas; let i = index">
                  <div class="open-ended-answers-row" *ngIf="!selfMark.correct">
                    <div class="open-ended-cell open-ended-checkbox" *ngIf="answersForApprove.reviewState !== 'APPROVED'">
                      <curr-checkbox
                        [value]="[]"
                        [options]="[{label: '', value: i}]"
                        [isMultipleSelect]="false"
                        type="checkbox"
                        (valueChange)="setCheckboxAnswer($event, i, selfMark.mark)"></curr-checkbox>
                    </div>
                    <div class="open-ended-cell open-ended-answer">
                      <div class="open-ended-title">Answer</div>
                      <div class="open-ended-desc" [currMath]="selfMark.key"></div>
                    </div>
                    <div class="open-ended-cell open-ended-mark">
                      <div class="open-ended-title">Mark</div>
                      <div class="open-ended-desc">{{ selfMark.mark }}</div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="self-mark-data-row" *ngIf="studentAnswer.selfMark.reasons.length > 0 && studentAnswer.selfMark.reasons[0]">
            <div class="row-title">Student Provided Insight</div>
            <div class="row-content">
              <div class="reason" *ngFor="let reason of studentAnswer.selfMark.reasons; let i = index">{{ reason }}</div>
            </div>
          </div>
          <div class="self-mark-data-row" *ngIf="studentAnswer.selfMark.comment">
            <div class="row-title">Student Comments</div>
            <div class="row-content" [currMath]="studentAnswer.selfMark.comment"></div>
          </div>
        </div>

        <div class="" *ngIf="!isLoadEditor">
          <div class="ai-comments" *ngIf="question.markType === 'AI_MARKED' && question.answerType === qt.OPEN_ENDED">
            <ng-container *ngIf="insights">
              <div class="ai-comments-label">What did the student forget to do</div>
              <div *ngIf="answersForApprove.reviewState === 'APPROVED'" [currMath]="insights || 'No data'"></div>
              <curr-tiny-editor
                *ngIf="!answersForApprove.reviewState || answersForApprove.reviewState === 'PENDING'"
                [options]="{ height: 190, disabled: false, menubar: 'menubar', statusbar: false }"
                (onFocusOut)="sendInsights($event)"
                [(value)]="insights">
              </curr-tiny-editor>
            </ng-container>

            <ng-container *ngIf="highlights">
              <div class="ai-comments-label">What are the general weaknesses identified in response</div>
              <div *ngIf="answersForApprove.reviewState === 'APPROVED'" [currMath]="highlights || 'No data'"></div>
              <curr-tiny-editor
                *ngIf="!answersForApprove.reviewState || answersForApprove.reviewState === 'PENDING'"
                [options]="{ height: 190, disabled: false, menubar: 'menubar', statusbar: false }"
                (onFocusOut)="sendHighlights($event)"
                [(value)]="highlights">
              </curr-tiny-editor>
            </ng-container>
          </div>

          <div class="teacher-comments-label" *ngIf="isShowAITeacherCommentsTitle()">Teacher Comments</div>
          <div *ngIf="answersForApprove.reviewState === 'APPROVED' && studentAnswer.textAnswer" [currMath]="studentAnswer.textAnswer"></div>
          <curr-tiny-editor
            *ngIf="isShowAITeacherComments()"
            [options]="{ height: 190, disabled: false, menubar: 'menubar', statusbar: false }"
            (onFocusOut)="sendTeacherComment($event)"
            (valueChange)="changeTeacherComment($event)"
            [(value)]="studentAnswer.textAnswer">
          </curr-tiny-editor>

          <div class="audion-file" *ngIf="audioFile || blob.blobUrl">
            <div class="audion-file-inside">
              <audio controls>
                <source [src]="audioFile || blob.blobUrl" type="audio/webm" />
              </audio>
              <mat-icon *ngIf="answersForApprove.reviewState !== 'APPROVED'" class="icon-trash" [svgIcon]="'trash'" (click)="deleteReply()"></mat-icon>
            </div>

            <div class="audion-file-btn" *ngIf="answersForApprove.reviewState !== 'APPROVED'">
              <curr-button [color]="'primary'" [variant]="'contained'" [icon]="'microphone'" (btnClick)="clickNewRecord()">
                RECORD NEW AUDIO REPLY
              </curr-button>
            </div>
          </div>
        </div>

        <div class="actions-section" [class.change-col]="(!question.markType || question.markType === 'SELF_MARKED')" *ngIf="answersForApprove.reviewState !== 'APPROVED'">
          <curr-button *ngIf="question.answerType === qt.OPEN_ENDED"
            size="big" [color]="'primary'" (btnClick)="clickNewRecord()">
            <mat-icon class="icon-micro" [svgIcon]="'microphone'"></mat-icon>
            RECORD AUDIO REPLY
          </curr-button>

          <div class="actions-second-section">
            <div class="actions-field-section marks-section">
              <div class="field-label">Marks</div>
              <curr-input [isCentered]="true" [value]="studentAnswer?.resultMark" class="number-input"
                          (onInputBlur)="sendTeacherMark($event)"></curr-input>
            </div>

            <div class="actions-field-section actions-select-field" *ngIf="(!question.markType || question.markType === 'SELF_MARKED')">
              <div class="field-label">Select response type</div>
              <curr-dropdown-component-v2
                [selectedValue]="studentAnswer.responseType || []"
                [options]="ddOptions"
                placeholder="Select response type"
                (selectedValueChange)="ddOptionsChange($event)">
              </curr-dropdown-component-v2>
            </div>
          </div>
        </div>

        <div class="bottom-section" *ngIf="answersForApprove.reviewState !== 'APPROVED'">
          <curr-button size="big" [color]="'primary'" (btnClick)="approveQuestion(studentAnswer.resultMark)">Approve</curr-button>
        </div>
      </div>
    </div>
    <div class="bottom-section-mark" *ngIf="answersForApprove.reviewState === 'APPROVED'">Marks Awarded: <span>{{studentAnswer?.resultMark}}</span></div>
  </curr-expansion-panel>
</div>
