/**
 * Created by Alex Poh. on 05/03/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ElementRef,
  ViewChildren,
  AfterViewInit,
  QueryList
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, Observable, of, pipe, Subject } from 'rxjs';
import {
  Paper,
  PaperResponse,
  SubjectDetails,
  LearningObjective,
  LearningObjectiveResponse,
  UnitResponse,
  TopicResponse
} from '../api-subject-details';
import { getRestApiProvider, RestApiService } from '../../../shared/api/rest-api.service';
import { catchError, switchMap, take, takeUntil} from 'rxjs/operators';
import { KeyValue } from '../../../shared/api/models/key-value';
import { OffsetTopDirective } from '../../../shared/directives/offset-top.directive';
import { ScrollableDirective } from '../../../shared/directives/scrollable.directive';
import { HostListener } from '@angular/core';
import { SubjectDetailsService } from '../subject-details.service';
import { FullScreenPopupService } from '../../../shared/components/full-screen-popup/full-screen-popup.service';
import {ToastrService} from 'ngx-toastr';
import { CreateEditDialogComponent } from 'src/app/shared/components/new-question-block/create-edit-dialog/create-edit-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { EditPaperNamePopupComponent } from './edit-paper-name-popup/edit-paper-name-popup.component';
import { CreateEditAboutPaperComponent } from './create-edit-about-paper/create-edit-about-paper.component';
import { DeleteAboutPaperPopupComponent } from './delete-about-paper-popup/delete-about-paper-popup.component';

export class CreateTopicModel {
  title = 'new topic';
  curriculum_paper_id: string;
  duration = 20;
  is_published = false;
  lessons = 'Big12';
  operation = 'CREATE';
  ref_num = 1;
  sequence_num;
  sow_custom_fields: KeyValue[] = [];
  curriculum_topic_id;
}

export class NavigationTree {
  unitLevels: UnitLevel[];

  static build({ curriculumUnits, curriculumTopics, learningObjectives }): NavigationTree {
    const unitLevels: UnitLevel[] = [];
    curriculumUnits.forEach(unit => {
      const topicLevels = NavigationTree.buildTopicLevels(
        { curriculumUnits, curriculumTopics, learningObjectives },
        unit
      );
      if (topicLevels.length > 0) {
        unitLevels.push({ unit, topicLevels });
      }
    });
    return { unitLevels };
  }

  static buildTopicLevels({ curriculumUnits, curriculumTopics, learningObjectives }, unit: UnitResponse) {
    const result = [];
    curriculumTopics.forEach(topic => {
      if (topic.curriculum_unit_id === unit.id) {
        const learningObj = NavigationTree.buildLearningObjectivesForTopic(
          { curriculumUnits, curriculumTopics, learningObjectives },
          topic
        );
        if (learningObj.length > 0) {
          result.push({ topic, learningObjectives: learningObj });
        }
      }
    });
    return result;
  }

  static buildLearningObjectivesForTopic({ curriculumUnits, curriculumTopics, learningObjectives }, topic) {
    let result = [];
    learningObjectives.forEach(learningObjective => {
      if (learningObjective.curriculum_topic_id === topic.id) {
        result.push(learningObjective);
      }
    });
    result = result.sort((a, b) => {
      return a.sequence_num - b.sequence_num;
    });
    return result;
  }
}

export class UnitLevel {
  unit: UnitResponse;
  topicLevels: TopicLevel[];
}

export class TopicLevel {
  topic: TopicResponse;
  learningObjectives: LearningObjective[];
}

@Component({
  selector: 'curr-paper-details',
  templateUrl: './paper-details.component.html',
  styleUrls: ['./paper-details.component.scss'],
  providers: [
    getRestApiProvider<LearningObjectiveResponse>('curriculumLearningObjectives', 'curriculumLearningObjectives'),
    getRestApiProvider<LearningObjectiveResponse>('curriculumPaperTopics', 'curriculumPaperTopics'),
    getRestApiProvider<CreateTopicModel>('curriculumLearningObjectives', 'createCurriculumTopics'),
    getRestApiProvider<SubjectDetails>('curriculums', 'curriculums'),
    getRestApiProvider<{ sow_descriptions: KeyValue[] }>('updateCurriculumSow', 'updateCurriculumSow'),
    getRestApiProvider<TopicResponse>('curriculumTopics', 'curriculumTopics'),
    getRestApiProvider<UnitResponse>('curriculumUnits', 'curriculumUnits'),
    FullScreenPopupService
  ]
})
export class PaperDetailsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('learningObjectiveDetails', { static: false }) learningObjectiveDetailsRef: ElementRef<HTMLElement>;
  @ViewChild('sidebarHeader', { static: false }) sidebarHeaderRef: ElementRef<HTMLElement>;
  @ViewChildren(OffsetTopDirective) listItems: QueryList<OffsetTopDirective>;
  @ViewChild(ScrollableDirective) list: ScrollableDirective;

  learningObjectivesListHeight: number;

  num: number;
  paperResponse: PaperResponse;
  paper: Paper;
  subject: SubjectDetails;
  curriculumId: string;
  units: UnitResponse[] = [];
  topics: TopicResponse[] = [];
  navigationTree: NavigationTree;
  isNewPaper = false;
  isInEditMode = true;

  selectedTopic: LearningObjective;
  onTopTopic: LearningObjective;
  schoolYearCourseId = undefined;
  fetchedLearningObjective: LearningObjective;
  // selectedGroup: TopicGroup;

  selectedMenuIndex = 1;

  newPaper$: Subject<Paper> = new Subject<Paper>();

  getTopics$: Observable<LearningObjective[]>;

  // newPaperWithAuditTime$: Subject<Paper> = this.newPaper$.asObservable();

  destroy$: Subject<boolean> = new Subject<boolean>();
  secNumPrefix = '';
  isDisabledAIBtn = false;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private cd: ChangeDetectorRef,
              @Inject('curriculumLearningObjectives') private learningObjectiveService: RestApiService<LearningObjectiveResponse>,
              @Inject('curriculumPapers') private paperService: RestApiService<Paper>,
              @Inject('createCurriculumTopics') private createCurriculumTopics: RestApiService<CreateTopicModel>,
              @Inject('updateCurriculumSow') private updateCurriculumSowService: RestApiService<{ sow_descriptions: KeyValue[] }>,
              @Inject('curriculumUnits') private unitService: RestApiService<UnitResponse>,
              @Inject('curriculumTopics') private topicsService: RestApiService<TopicResponse>,
              private subjectDetailsService: SubjectDetailsService,
              private fullScreenPopupService: FullScreenPopupService,
              private toastr: ToastrService,
              public dialog: MatDialog) {
  }

  @HostListener('window:popstate', ['$event'])
  onPopState() {
    this.subjectDetailsService.paperBackBtnSubject.next(true);
  }

  ngOnInit(): void {
    this.route.parent.params.pipe(take(1)).subscribe(res => {
      this.schoolYearCourseId = res?.schoolYearCourseId;
    });
    if (this.route.snapshot.data.paper) {
      this.initPaper();
    }
    // this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
    //   if (this.route.snapshot.data.paper) {
    //     this.initPaper();
    //   }
    // });
    this.setFloatingSave();
    this.fullScreenPopupService.fullScreenPopupCounter.pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.isDisabledAIBtn = res > 0;
    });
  }

  ngAfterViewInit() {
    if (this.route.parent.snapshot.paramMap.get('schoolYearCourseId')) {
      this.learningObjectiveDetailsSizeObserver();
    }
  }

  initPaper() {
    this.isNewPaper = this.route.snapshot.parent?.data.isNew;
    this.paperResponse = this.route.snapshot.data.paper;
    this.newPaper$
      .asObservable()
      .pipe(
        switchMap((paper: Paper) => {
          this.paper = paper;
          this.toastr.success('Paper Name was successfully updated');
          return this.paperService.update(paper, this.paper.id);
        })
      )
      .subscribe(res => {
      });
    if (this.paperResponse) {
      this.paper = this.paperResponse.paper;
    }

    if (this.isNewPaper) {
      this.subject = this.route.parent.snapshot.data.subjectDetails;
    }
    this.subject = this.route.parent.snapshot.data.subjectDetails;

    this.curriculumId = this.route.parent.snapshot.paramMap.get('subjectId');
    if (this.paperResponse?.curriculumUnits.length > 0) {
      if (this.paperResponse?.learningObjectives?.length > 0) {
        this.route.snapshot.queryParams.topic
          ? this.selectLearningObjectiveById(this.route.snapshot.queryParams.topic)
          : this.selectLearningObjective(this.paperResponse?.learningObjectives[0]);
      }
    } else {
      this.addStartingUnitWithTopic();
    }
    this.selectedMenuIndex = this.route.snapshot.queryParams.menu;
    this.buildNavigationTree();
  }

  buildNavigationTree() {
    this.navigationTree = NavigationTree.build(this.paperResponse);
  }

  selectLearningObjectiveById(id: string) {
    this.selectLearningObjective(this.paperResponse?.learningObjectives.find(topic => topic.id === id));
  }

  selectLearningObjective(topic: LearningObjective, topicIndex?: number) {
    this.selectedTopic = topic;
    // Fix for removing mat-divider that positioning on top of selected topic.
    if (topicIndex) {
      this.onTopTopic = this.paperResponse.learningObjectives[topicIndex - 1];
    }
    this.learningObjectiveService.getById(topic.id).subscribe((res: LearningObjectiveResponse) => {
      this.fetchedLearningObjective = res.learningObjective;
      this.setSecNumPrefixFromNavigationTree(this.fetchedLearningObjective);
    });
  }

  getQuestions(event) {
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  addAbout() {
    if (!this.subject.sowDescriptions) {
      this.subject.sowDescriptions = [];
    }
    this.subject.sowDescriptions.push({ key: '', value: '' });
    this.updateSow();
  }

  openAiAssistant() {
    const ddFilteredOptionsTopicClear = this.paperResponse.learningObjectives.map(item => {
      return { value: item.id, label: item.title.replace(/<[^>]*>/g, '') }
    });

    const ref = this.fullScreenPopupService.open(CreateEditDialogComponent, {
      options: {
        dialogTitle: 'AI Assistant',
        isFullScreen: true,
        showFullScreenBtn: false
      },
      data: {
        dialogSubTitle: 'Add Question',
        dialogType: 'ai',
        dialogMode: 'create',
        topicList: ddFilteredOptionsTopicClear,
        curriculumId: this.paper.curriculum_id,
      }
    });

    ref.instance.onClose.subscribe(res => {
      // this.toastr.success('The question was created successfully');
    });
  }

  private updateSow() {
    this.updateCurriculumSowService
      .update({ sow_descriptions: this.subject.sowDescriptions }, this.subject.id)
      .subscribe();
  }

  addLearningObjective(topicId: number) {
    let newLearningObjectiveId;
    this.createCurriculumTopics
      .add({
        ...new CreateTopicModel(),
        curriculum_paper_id: this.paper.id,
        curriculum_topic_id:
          topicId || this.paperResponse.curriculumTopics[this.paperResponse.curriculumTopics.length - 1].id
      })
      .pipe(
        switchMap((res: any) => {
          newLearningObjectiveId = res.id;
          return this.paperService.getById(this.paper.id);
        })
      )
      .subscribe((res: any) => {
        this.paperResponse = res;
        this.paperResponse.learningObjectives = res.learningObjectives as LearningObjective[];
        this.subject.learningObjectives = res.learningObjectives as LearningObjective[];
        this.buildNavigationTree();
        this.selectLearningObjective(res.learningObjectives.find(lo => lo.id === newLearningObjectiveId));

        this.cd.detectChanges();
      });
  }

  addStartingUnitWithTopic() {
    return this.unitService
      .add({
        curriculum_paper_id: this.paperResponse.paper.id,
        is_published: false,
        sequence_num: 1,
        title: 'unit'
      })
      .pipe(
        switchMap(res => {
          return this.topicsService.add({
            curriculum_unit_id: res.id,
            is_published: false,
            sequence_num: 1,
            title: 'topic'
          });
        })
      )
      .pipe(
        switchMap(res => {
          return this.paperService.getById(this.paperResponse.paper.id);
        })
      )
      .subscribe(res => {
        this.paperResponse = res as any;
      });
  }

  onTopicUpdate(val: LearningObjective) {
    this.paperService.getById(this.paperResponse.paper.id).subscribe(res => {
      this.paperResponse = res as any;
      this.buildNavigationTree();
      this.paperResponse.learningObjectives.forEach(topic => {
        if (topic.id === this.selectedTopic.id) {
          this.selectLearningObjective(topic);
        }
      });
    });
  }

  public updatePaperPipe = () =>
    pipe(
      catchError(err => EMPTY),
      switchMap((paper: Paper) => {
        return this.paperService.update(paper, this.paper.id);
      })
    );

  createNewCT() {
    if (this.schoolYearCourseId) {
      this.router.navigate(['edit-test', this.route.snapshot.params.paperId, 'new', this.schoolYearCourseId]);
    }
  }

  deleteCTCard(i: number) {
    this.paperResponse.cumTests.splice(i, 1);
  }

  private setFloatingSave() {
    let startProductBarPos = -1;
    window.onscroll = () => {
      const bar = document.getElementById('nav-sow');
      if (!bar) {
        return;
      }
      if (startProductBarPos < 0) {
        startProductBarPos = findPosY(bar);
      }
      const rect = bar?.parentElement.getBoundingClientRect();
      if (pageYOffset > startProductBarPos) {
        bar.style.position = 'fixed';
        bar.style.top = 0 + 'px';
        bar.style['min-width'] = rect.width + 'px';
        bar.classList.add('custom-shadow-sow');
      } else {
        bar.style.position = 'relative';
        bar.style.top = '0';
        bar.classList.remove('custom-shadow-sow');
      }
    };

    function findPosY(obj) {
      let curtop = 0;
      if (typeof obj?.offsetParent !== 'undefined' && obj?.offsetParent) {
        while (obj?.offsetParent) {
          curtop += obj?.offsetTop;
          obj = obj?.offsetParent;
        }
        curtop += obj?.offsetTop;
      } else if (obj?.y) {
        curtop += obj?.y;
      }
      return curtop;
    }
  }

  setSecNumPrefix(i: number, j: number) {
    this.secNumPrefix = `${ i }.${ j }.`;
  }

  private setSecNumPrefixFromNavigationTree(lo: LearningObjective) {
    const { id } = lo;
    this.navigationTree.unitLevels.forEach(u => {
      u.topicLevels.forEach(t => {
        const index = t.learningObjectives.findIndex(l => l.id === id);
        if (index > -1) {
          this.secNumPrefix = `${ u.unit.sequence_num }.${ t.topic.sequence_num }.`;
        }
      });
    });
  }

  // obseerve height changes of learningObjectiveDetails div
  private learningObjectiveDetailsSizeObserver(): void {
    if (this.learningObjectiveDetailsRef) {
      const resizeObserver = new ResizeObserver(entries => {
        entries.forEach((entry) => {
          const cr = entry.contentRect;
          this.learningObjectivesListHeight = cr.height - this.sidebarHeaderRef.nativeElement.offsetHeight - 24;
          this.scrollToCurrentTopic();
          this.cd.detectChanges();
        });
      });
      resizeObserver.observe(this.learningObjectiveDetailsRef.nativeElement);
    }
  }

  private scrollToCurrentTopic(): void {
    const currentTopic = this.listItems.find(item => {
      /* tslint:disable:no-string-literal */
      const elRef = item['el'] as ElementRef<HTMLElement>;
      return elRef.nativeElement.classList.contains('glow');
    });
    this.list.scrollTop = currentTopic.offsetTop;
  }

  editPaperName() {
    const dialogRef = this.dialog.open(EditPaperNamePopupComponent, {
      width: '660px',
      data: this.paper.title ? this.paper.title : 'New Paper'
    });

    dialogRef.afterClosed().subscribe(titleValue => {
      if (titleValue) {
        this.newPaper$.next({ ...this.paper, title: titleValue });
      }
    });
  }

  addAboutPaper() {
    const ref = this.fullScreenPopupService.open(CreateEditAboutPaperComponent, {
      options: {
        dialogTitle: 'Add About Paper',
        isFullScreen: false,
        showFullScreenBtn: true,
      },
      data: {
        closeDialogTitle: 'Close Add About Paper?',
        showContent: false,
        dialogMode: 'create',
      },
    });

    ref.instance.onClose.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      if (res.data && res.data.aboutText && res.data.aboutText !== '') {
        if (this.subject.sowDescriptions) {
          this.subject.sowDescriptions.push({ key: res.data.aboutName, value: res.data.aboutText });
        } else {
          this.subject.sowDescriptions = [{ key: res.data.aboutName, value: res.data.aboutText }];
        }
        
        this.updateSow();
        this.toastr.success('About Paper Successfully Added!');
      }
    });
  }

  editAboutPaper(event) {
    console.log(event)
    const refEdit = this.fullScreenPopupService.open(CreateEditAboutPaperComponent, {
      options: {
        dialogTitle: 'Edit About Paper',
        isFullScreen: false,
        showFullScreenBtn: true,
      },
      data: {
        closeDialogTitle: 'Close Edit About Paper?',
        showContent: false,
        dialogMode: 'edit',
        oldAboutName: event.item.key,
        oldAboutText: event.item.value,
      },
    });

    refEdit.instance.onClose.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      if (res.data && res.data.aboutText && res.data.aboutText !== '') {
        this.subject.sowDescriptions[event.index].key = res.data.aboutName;
        this.subject.sowDescriptions[event.index].value = res.data.aboutText;
        this.updateSow();
        this.toastr.success('About Paper Successfully Edited!');
      }
    });
  }

  onDeleteAbout(i: number) {
    const dialogRef = this.dialog.open(DeleteAboutPaperPopupComponent, {
      width: '424px',
      data: {
        title: 'Delete About Paper?',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        if (result) {
          this.subject.sowDescriptions.splice(i, 1);
          this.updateSow();
          this.toastr.success('About Paper Successfully Deleted!');
        }
      });
  }
}
