/*
 * Created by Dmytro Sav. on 4/5/2020
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'curr-paper-info-item',
  templateUrl: './paper-info-item.component.html',
  styleUrls: ['./paper-info-item.component.scss'],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('200ms', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('200ms', style({ opacity: 0 }))])
    ])
  ]
})
export class PaperInfoItemComponent implements OnInit {
  @Input() about: { key: string, value: string };
  @Output() onEditAbout = new EventEmitter<{ key: string, value: string }>();
  @Output() onDeleteAbout = new EventEmitter();

  @Input() isDisabled? = false;

  isShowed = false;
  isError = false;

  constructor() {
  }

  ngOnInit() {}

  toggleDropdown() {
    if (!this.isDisabled) {
      this.isShowed = !this.isShowed;
    }
  }

  closeDropDown() {
    this.isShowed = false;
  }


  editItem() {
    this.onEditAbout.emit({ key: this.about.key, value: this.about.value });
  }

  deleteItem() {
    this.onDeleteAbout.emit();
  }
}
