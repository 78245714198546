/**
 * Created by Alex Poh. on 31/03/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

export interface ITag {
  id?: number;
  tag: string;
}
export interface Test {
  name: string;
  subject: Subject;
  questions: Question[];
}

export interface Subject {
  name: string;
}

export class Question {
  constructor(name: string = 'Question', topicId: number | string = '15', type: string = QuestionType.SELECT_ONE) {
    this.answered = false;
    this.hint = '';
    this.answer_status = 'NO_ANSWER';
    this.is_correct = null;
    this.question = name;
    this.curriculum_lobj_id = topicId;
    this.question_file = '';
    this.answer_file = '';
    this.question_image = '';
    this.answer_image = '';
    this.oeq_keywords = [];
    this.answer_options = ['', ''];
    this.answer_options_competence = {};
    this.correct_answer_options[0] = 1;
    this.answer_type = type;
    this.mark = 1;
    this.base_type = 'ct';
    this.estimated_time = 1;
    this.isSelected = false;
    this.is_master = false;
    this.need_working = true;
    this.is_past_exam_question = false;
    this.is_published = false;
    this.cou_right_id = null;
    this.cou_id = null;
    this.isAutomarked = null;
    this.cou_wrong_id = null;
    this.question_code = 0;
    this.question_order = 0;
    this.change_status = 'NO_CHANGE';
    this.difficulty_level = undefined;
    this.children = [];
    this.questionYear = new Date().getFullYear();
    this.question_year = new Date().getFullYear();
    this.video_url = null;
    this.student_correct_answer_options = [];
    this.student_cou_right = '';
    this.student_cou_wrong = '';
    this.student_answer_text = '';
    this.examination_board = null;
    this.testAndQuestionTypes = [];
    this.markType = 'SELF_MARKED';
    this.comments = '';
    this.matchNumber = null;
    this.itemNumber = null;
  }
  answered: boolean;
  student_answer_text: string;
  student_cou_wrong: string;
  student_cou_right: string;
  student_correct_answer_options: [];
  is_correct: boolean;
  hint: string;
  answer_status: string;
  children: Question[];
  base_type: 'sa' | 'ct';
  difficulty_level: string;
  questionYear: number;
  is_published: boolean;
  need_working: boolean;
  is_master: boolean;
  curriculum_lobj_id: string | number;
  question: string;
  change_status: string;
  question_image?: any;
  answer_image?: string;
  question_file?: string;
  answer_file?: string;
  id?: number;
  parent_id?: number;
  correct_answer_options?: number[] = [];
  oeq_keywords?: { key: string; mark: number }[] = [];
  answer_type: string;
  answer_options?: string[];
  answer_options_competence?: { [key: string]: string };
  answer_options_tags?: { [key: string]: ITag[] } | string;
  cou_right_id?: string | number;
  cou_id?: string | number;
  cou_wrong_id?: string | number;
  rubric?: string;
  mark?: number | string;
  estimated_time?: number;
  isSelected?: boolean;
  isAutomarked?: boolean;
  hasCou? = false;
  is_past_exam_question? = false;
  question_year?: number;
  couOnCorrect?: string;
  couOnIncorrect?: string;
  previewQuestion?: string;
  question_code?: number;
  question_order?: number;
  formulaEditor?: string;
  video_url?: string;
  comments?: string;
  testAndQuestionTypes?: string[];
  examination_board?: { name: string; country: string; id: string };
  markType?: 'AI_MARKED' | 'SELF_MARKED';
  questionAnswer?: 'CORRECT' | 'PARTIALLY_CORRECT' | 'INCORRECT';
  matchNumber?: string | null;
  itemNumber?: string | null;
}

export interface Answer {
  value: string;
  optionNumber?: number;
  explanation?: string;
  isCorrect: boolean;
}

export enum QuestionType {
  OPEN_ENDED = 'OPEN_ENDED',
  SELECT_MULTIPLE = 'MULTI_SELECT',
  SINGLE_ANSWER = 'SINGLE_ANSWER',
  TRUE_OR_FALSE = 'TRUE_FALSE',
  FILE_INPUT = 'FILE',
  MULTIPART = 'MULTI_LEVEL',
  SELECT_ONE = 'MULTI_CHOICE',
}

export enum QuestionsLabels {
  OPEN_ENDED = 'Open-Ended',
  MULTI_SELECT = 'Multiple Choice',
  SINGLE_ANSWER = 'Single Choice',
  TRUE_FALSE = 'True or False',
  FILE = 'File',
  MULTI_LEVEL = 'Multi-level Questions',
  MULTI_CHOICE = 'Multiple Choice',
}

export enum QuestionGlobalType {
  SIMPLE = 'simple',
  MULTI_LEVEL = 'multi_level'
}

export enum QuestionGlobalTypeLabels {
  SIMPLE = 'Simple Question',
  MULTI_LEVEL = 'Multi-level Question'
}

export interface CommonQuestionAnswer {
  student_cum_test_id?: number;
  student_cum_test_question_id?: number;
  student_sa_test_question_id?: number;
  curriculum_topic_id?: number;
  ct_question_id?: number;
  answer_solution?: string;
  student_correct_answer_options?: any[];
  student_answer_text?: string;
  student_cou?: string;
  student_answer_file?: any;
  student_cou_right?: string;
  student_cou_wrong?: string;
  is_correct?: boolean;
}

export interface SAQuestionAnswer {
  answer_status?: string;
  is_correct?: boolean;
  student_sa_test_question_id?: number;
  student_sa_test_id?: number;
  topic_sa_question_id?: number;
  curriculum_topic_id?: number;
  student_correct_answer_options?: any[];
  student_answer_text?: string;
  student_answer_file?: any;
  student_cou_right?: string;
  student_cou_wrong?: string;
}
