<!--
 Created by Volodymyr Mak. on 7/5/2020.
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->
<div class="profile-main-title">
  My Profile
</div>
<div class="student-profile" fxLayoutGap="24px" fxLayoutAlign="center stretch" fxLayout="row" fxLayout.xs="column">
  <div class="profile-left-panel">
    <curr-profile-card (updateUserCard)="updateUserProfile($event)" [profileData]="profileData"></curr-profile-card>

    <ng-container *currHasRoles="[userRole.SchoolAdmin, userRole.Teacher]">
      <curr-notes-about-student *ngIf="currUser && studId" [currUser]="currUser" [studId]="studId">
      </curr-notes-about-student>
    </ng-container>
    <curr-student-bio-card></curr-student-bio-card>
  </div>

  <div class="profile-right-panel">
    <article class="profile-soft-q m-b-16">
      <!-- <div class="title" fxLayoutAlign="space-between">
        <span>Soft Questions</span>
        <curr-button (btnClick)="toggleEditSoftQ()" [icon]="!softQEdit ? 'edit' : 'check'" [style]="{ border: 'none' }"
          size="small" variant="outlined">
          {{ !softQEdit ? 'EDIT' : 'SAVE' }}
        </curr-button>
      </div>
      <mat-divider></mat-divider> -->

      <div class="q-content referral-activity m-b-24" [@fade]>
        <div  class="referral-activity-title">
          <div class="q-content-title">
            referral Activity
          </div>
          <a class="link" [routerLink]="['/referral']">View All</a>
        </div>

        <div class="referral-activity-main" *ngIf="userReferralInfo$ | async as referralUrl">
          <div class="q-block q-link-wrapper" [ngClass]="{ 'p-b-8': softQEdit }" [@fade]>
            <div class="title">Your Referral Link</div>
            <div class="q-link">
              <input type="text" class="q-link-text"
                [value]="origin + '/sign-up/' + referralUrl.referralCode" />
              <curr-button class="copy-icon copy-btn" [icon]="copyIcone" [style]="btnStyle" color="accent" variant="contained"
                size="small" (btnClick)="copyLink(referralUrl.referralCode)">Copy</curr-button>
            </div>
          </div>

          <div class="q-block-item">
            <div class="q-referral-balance">£{{ referralUrl.referralBalance }}</div>
            <div class="q-referral-message" >Your referral balance</div>
          </div>

          <div class="refferal-img">
            <mat-icon [inline]="true" class="image" [svgIcon]="referralIcone"></mat-icon>
          </div>
        </div>
      </div>

<!--      <div class="q-content soft-questions m-b-24" [@fade]>-->
<!--        <div  fxLayout="row">-->
<!--          <div class="q-content-title">-->
<!--            PASSIONS AND INTERESTS-->
<!--          </div>-->
<!--          <mat-icon class="edit-icon" *ngIf="!softQEdit" svgIcon="edit" (click)="toggleEditSoftQ()"></mat-icon>-->
<!--        </div>-->
<!--        &lt;!&ndash; <div class="q-block" [ngClass]="{ 'p-b-8': softQEdit }">-->
<!--          <div class="title">How do you learn best?</div>-->
<!--          <div class="tag-wrapper" fxLayout="row wrap" fxLayoutGap="8px">-->
<!--            <ng-container *ngIf="!softQEdit; else learnBestChips">-->
<!--              <div class="tag" *ngFor="let chips of softQuestion.learnBestChips">-->
<!--                {{ chips }}-->
<!--              </div>-->
<!--            </ng-container>-->
<!--          </div>-->
<!--        </div> &ndash;&gt;-->
<!--        <div class="q-block" [ngClass]="{ 'p-b-8': softQEdit }">-->
<!--          <div class="tag-wrapper" fxLayout="row wrap" fxLayoutGap="8px">-->
<!--            <ng-container *ngIf="!softQEdit; else interestsChips">-->
<!--              <div class="tag" *ngFor="let hobbies of userProfile?.hobbies" [@fade]>-->
<!--                {{ hobbies }}-->
<!--              </div>-->
<!--            </ng-container>-->
<!--          </div>-->
<!--          <div>-->
<!--            <curr-button variant="text" *ngIf="softQEdit" [style]="cancelButtonStyle" color="primary" size="small"-->
<!--              (btnClick)="cancelChanges()">-->
<!--              CANCEL-->
<!--            </curr-button>-->
<!--            <curr-button *ngIf="softQEdit" (click)="saveHobbies()" class="subject-btn profile-btn">-->
<!--              Save-->
<!--            </curr-button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

      <div class="q-content your-subscription m-b-24" [@fade]>
        <div class="your-subscription-heading">
          <div class="q-content-title" [@fade]>
            Your Subscription
          </div>
          <curr-button (btnClick)="goToAddSubjectsPage()" class="subject-btn profile-btn">
            + Add Subject
          </curr-button>
        </div>

        <div class="table-responsive">
          <table class="subscription-table" mat-table [dataSource]="dataSource" matSort aria-describedby="users desc">
            <ng-container matColumnDef="subjectName" >
              <th mat-header-cell *matHeaderCellDef scope="col">Subject</th>
              <td mat-cell *matCellDef="let element" [@fade]>
                <div class="user-name" >{{ element.subjectName }}</div>
              </td>
            </ng-container>

            <ng-container matColumnDef="plan">
              <th mat-header-cell *matHeaderCellDef scope="col">Plan</th>
              <td mat-cell *matCellDef="let element" [@fade]>
                <div class="user-name">{{ element.paymentPlanType }}</div>
              </td>
            </ng-container>

            <ng-container matColumnDef="subscriptionStatus">
              <th mat-header-cell *matHeaderCellDef scope="col">Status</th>
              <td mat-cell *matCellDef="let element" [@fade]>
                <div class="user-name">
                  <div>{{ element.subscriptionStatus }}</div>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef scope="col">Payment</th>
              <td mat-cell *matCellDef="let element" [@fade]>
                <div class="user-name" fxFlex fxLayout="column" fxLayoutAlign="start start">
                  <div class="payment-amount">£{{ element.amount }}</div>
                  <div class="expirationDate">
                    {{getFormatedData(element.expirationDate) | date: 'mediumDate'	}}
                  </div>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="lastPaymentStatus">
              <th mat-header-cell *matHeaderCellDef scope="col"></th>
              <td mat-cell *matCellDef="let element" [@fade]>
                <div fxFlex fxLayout="row" fxLayoutAlign="flex-end center">
                  <curr-button [style]="btnStyle" color="accent" variant="contained"
                    *ngIf="element.lastPaymentStatus==='UNPAID'" (click)="goToAddSubjectsPage()">
                    Renew
                  </curr-button>
                  <curr-button [style]="btnStyle" color="accent" variant="contained"
                    *ngIf="element.lastPaymentStatus==='PAID' " (click)="cancelSubscription(element.id)">
                    Cancel subscription
                  </curr-button>
                  <curr-button [style]="btnStyle" color="accent" variant="contained" (click)="refundPayment()"
                    *ngIf="element.lastPaymentStatus==='CANCELLED' && !isLimitDatePassed">
                    Refund
                  </curr-button>
                  <div class="payment-amount" *ngIf="element.lastPaymentStatus==='CANCELLED' && isLimitDatePassed">
                    {{element.lastPaymentStatus}}
                  </div>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </div>
    </article>
    <ng-container *currHasRoles="[userRole.SchoolAdmin, userRole.Teacher]">
      <curr-dashboard-chart *currHasRoles="[userRole.Teacher]" noDataImage="assets/images/no-graph-data.png">
      </curr-dashboard-chart>
      <curr-school-admin-chart *currHasRoles="[userRole.SchoolAdmin]"></curr-school-admin-chart>
      <!--      <curr-student-dashboard-chart></curr-student-dashboard-chart>-->
      <curr-student-profile-test-archive [testArchive]="testArchive" *ngIf="testArchive.length">
      </curr-student-profile-test-archive>
      <curr-student-profile-subject-table [tableData]="tableData"></curr-student-profile-subject-table>
    </ng-container>
  </div>
</div>

<ng-template #learnBestChips>
  <mat-chip-list #chipList>
    <mat-chip *ngFor="let chips of softQuestion.learnBestChips" [selectable]="true" [removable]="true"
      (removed)="removeChips(softQuestion.learnBestChips, chips)">
      {{ chips }}
      <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
    </mat-chip>
    <input placeholder="New learn approaches..." class="chips-input" [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
      (matChipInputTokenEnd)="addChips($event, softQuestion.learnBestChips)" />
  </mat-chip-list>
</ng-template>

<ng-template #interestsChips>
  <mat-chip-list #interestsChipList>
    <mat-chip *ngFor="let hobbies of userProfile.hobbies" [selectable]="true" [removable]="true"
      (removed)="removeChips(userProfile.hobbies, hobbies)">
      {{ hobbies }}
      <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
    </mat-chip>
    <input placeholder="New interests..." class="chips-input" [matChipInputFor]="interestsChipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
      (matChipInputTokenEnd)="addChips($event, userProfile.hobbies)" />
  </mat-chip-list>
</ng-template>
