/**
 * Created by Dmytro Sav. on 4/6/2020
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

/**
 *
 * Usage example
 * <curr-user-card [isDeactivated]='true' userData='{}'> </curr-user-card>
 *
 * userData: { name: string, id: string, subject: string,  role: Role,  status: string,  img: string  }
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { FileUploadService } from '../edit-question-block/file-upload.service';

@Component({
  selector: 'curr-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss']
})
export class UserCardComponent implements OnInit {
  isTeacher;

  @Input() isDeactivated: boolean;

  @Input() userData: any;
  @Output() onDeleteUser = new EventEmitter();

  constructor(private fileUploadService: FileUploadService,
              public dialog: MatDialog) {
  }

  ngOnInit(): void {
    if (this.userData.photo) {
      this.uploadAvatar();
    }
  }

  activateUser() {
    this.userData.deactivated = false;
    this.isDeactivated = false;
  }

  private uploadAvatar() {
    // TODO: remove?
    console.log('upl', this);
    this.fileUploadService
      .download(this.userData.photo, 'IMAGE')
      .subscribe((resp: any) => {
        const bytes = resp.data?.bytes;
        if (bytes != null) {
          this.userData.avatar = 'data:image/png;base64,' + bytes;
        }
      });

  }

  deleteUser() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      data: {
        questionTitle: 'Delete this user?'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.onDeleteUser.emit();
      }
    });
  }

}
