/**
 * Created by Yaroslav S. on 10/07/2024
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatRadioButton } from '@angular/material/radio';
import { Subject, takeUntil } from 'rxjs';
import { ManagingApiKeysService } from '../managing-api-keys.service';
import { ToastrService } from 'ngx-toastr';
import { IAiSubjectSettings, IAiSubjectSettingsParams, IDataToSave } from '../managing-api-keys.interface';
import { IAiSubjectsTableStructure } from './ai-subjects-table.interface';

const tableFields: IAiSubjectsTableStructure = {
  fields: [{ fieldName: 'subjectName', fieldFilter: 'all' }],
};

@Component({
  selector: 'curr-ai-subjects-table',
  templateUrl: './ai-subjects-table.component.html',
  styleUrls: ['./ai-subjects-table.component.scss'],
})
export class AiSubjectsTableComponent implements OnInit, OnDestroy {

  @Input() tableInitData: IAiSubjectSettings;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  isDataLoading = false;
  isDataPresented = true;
  resultsLength = 0;
  currentPageIndex = 0;
  customTables: IAiSubjectsTableStructure = tableFields;
  isApplyAll = false;
  isEditLine = false;
  dataToSave: IDataToSave = {hasChanges: false, subjectName: '', children: []};

  // TABLE
  SUBJECTS_COLUMNS = [];
  SUBJECTS_DATA = [];
  ELEMENT_DATA = [];
  displayedColumns: string[] = [];
  dataSource: any;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private managingApiKeysService: ManagingApiKeysService,
  ) {}

  ngOnInit(): void {
    this.initTableData();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initTableData(): void {
    if (this.tableInitData) {
      this.isDataPresented = true;
      this.resultsLength = this.tableInitData.totalResults;
      this.currentPageIndex = this.tableInitData.pageIndex;

      this.tableInitData.results = this.tableInitData.results.map((item, key) => {
        item.index = key;
        item.editMode = false;

        if (item.levelSettings.length > 0) {
          item.levelSettings.forEach((levelItem) => {
            if (!this.SUBJECTS_COLUMNS.includes(levelItem.levelId)) this.SUBJECTS_COLUMNS.push(levelItem.levelId);
          });
        }

        return item;
      });

      this.SUBJECTS_COLUMNS = this.SUBJECTS_COLUMNS.sort((a, b) => a - b);
      this.SUBJECTS_DATA = [];
      this.displayedColumns = [];
      this.SUBJECTS_DATA.push(...this.tableInitData.results);
      this.setTeachersTableData();

      return;
    }

    this.isDataPresented = false;
  }

  onPageChange(pageIndex: number) {
    this.getTeachersStatistic(pageIndex);
  }

  getTeachersStatistic(offset = 0) {
    this.isDataLoading = true;
    const o: IAiSubjectSettingsParams = {
      rowsCount: 10,
      rowOffset: offset,
    };

    this.managingApiKeysService
      .getAiSubjectSettings(o)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res: IAiSubjectSettings) => {
          if (!res.results.length) {
            this.isDataPresented = false;
            return;
          } else {
            this.isDataPresented = true;
            this.resultsLength = res.totalResults;
            this.currentPageIndex = res.pageIndex;

            res.results = res.results.map((item, key) => {
              item.index = key;
              item.editMode = false;

              if (item.levelSettings.length > 0) {
                item.levelSettings.forEach((levelItem) => {
                  if (!this.SUBJECTS_COLUMNS.includes(levelItem.levelId)) this.SUBJECTS_COLUMNS.push(levelItem.levelId);
                });
              }

              return item;
            });

            this.SUBJECTS_COLUMNS = this.SUBJECTS_COLUMNS.sort((a, b) => a - b);
            this.SUBJECTS_DATA = [];
            this.displayedColumns = [];
            this.SUBJECTS_DATA.push(...res.results);
            this.setTeachersTableData();
          }
        },
        (error) => {
          this.toastr.error('Failed to load subjects data');
        },
        () => {
          this.isDataLoading = false;
        },
      );
  }

  setTeachersTableData() {
    this.customTables.fields.forEach((i) => {
      this.displayedColumns.push(i.fieldName + '');
    });

    this.SUBJECTS_COLUMNS.forEach((item) => {
      this.displayedColumns.push(item + '');
    });

    this.displayedColumns.push('actions');
    this.dataSource = new MatTableDataSource(this.SUBJECTS_DATA);
    this.cdr.detectChanges();
  }

  editTableItem(index: number): void {
    this.isEditLine = true;
    this.SUBJECTS_DATA[index].editMode = !this.SUBJECTS_DATA[index].editMode;
    this.dataToSave.hasChanges = false;
    this.dataToSave.subjectName = this.SUBJECTS_DATA[index].subjectName;
    this.dataToSave.children = this.SUBJECTS_DATA[index].levelSettings;
    this.cdr.detectChanges();
  }

  saveTableItem(index: number): void {
    if (!this.dataToSave.hasChanges) {
      this.isEditLine = false;
      this.SUBJECTS_DATA[index].editMode = !this.SUBJECTS_DATA[index].editMode;
      this.cdr.detectChanges();
      return;
    }

    this.managingApiKeysService.saveAiSubjectSettings(this.dataToSave, this.isApplyAll)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.isEditLine = false;
        this.SUBJECTS_DATA[index].editMode = !this.SUBJECTS_DATA[index].editMode;

        if (this.isApplyAll) {
          this.SUBJECTS_DATA.forEach((item, i) => {
            this.SUBJECTS_DATA[i].levelSettings.forEach((levelChild, k) => {
              const foundElement = this.dataToSave.children.find((childItem) => childItem.levelId === levelChild.levelId);
          
              if (foundElement) {
                this.SUBJECTS_DATA[i].levelSettings[k].aiSubscriptionSettings = foundElement.aiSubscriptionSettings;
              }
            });
          });
        } else {
          this.SUBJECTS_DATA[index].levelSettings = this.dataToSave.children;
        }
        
        this.dataToSave = {hasChanges: false, subjectName: '', children: []};
        this.cdr.detectChanges();
        this.toastr.success('Changes Saved');
      });
  }

  setItemValue(
    selectedValue: { source: MatRadioButton; value: 'NOT_APPLIED' | 'GPT3_5_TURBO' | 'GPT4' | 'GPT_4_TURBO' },
    levelId: number,
  ) {
    const elementToFind = this.dataToSave.children.find((child) => child.levelId === levelId);
    const foundIndex = this.dataToSave.children.indexOf(elementToFind);

    if (foundIndex >= 0) {
      this.dataToSave.children[foundIndex].aiSubscriptionSettings = selectedValue.value;
      this.dataToSave.hasChanges = true;
    }
  }

  transformAiTitle(value: 'NOT_APPLIED' | 'GPT3_5_TURBO' | 'GPT4' | 'GPT_4_TURBO') {
    if (value === 'GPT3_5_TURBO') return 'GPT-3.5 Turbo';
    if (value === 'GPT4') return 'GPT-4';
    if (value === 'GPT_4_TURBO') return 'GPT-4 Turbo';
    return '-';
  }
}
