<!-- Created by Maxim B. on 30/03/20.-->
<!-- Copyright © 2020 SEVEN. All rights reserved.-->

<mat-form-field
  appearance="outline"
  [formGroup]="formGroup"
  color="primary"
  [ngClass]="{ 'only-one-row': rows === 1, 'not-empty-ta': formGroup.controls[controlName].value && rows === 1 }"
  class="curr-textarea"
  fxFlexFill
>
  <mat-label>{{ label }}</mat-label>
  <textarea
    matInput
    (keyup)="updateValue()"
    cdkTextareaAutosize
    [formControlName]="controlName"
    [cdkAutosizeMinRows]="rows"
    [cdkAutosizeMaxRows]="rows"
    [value]="value"
    class="core-textarea"
    (blur)="onBlur()"
  ></textarea>
  <mat-error *ngIf="formGroup.controls[controlName]?.errors">{{ errorMessage }}</mat-error>
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
</mat-form-field>
