/**
 * Created by Volodymyr Mak. on 7/5/2020.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, OnInit, OnDestroy } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

import { Subscription } from 'rxjs';
import { ApplicationHttpClient } from 'src/app/shared/api/application-http-client';

import { StudentSoftQuestions } from 'src/app/student/profile/profile.interface';
import { StudentProfileService } from 'src/app/student/profile/student-profile.service';
import { ProfileDataService } from 'src/app/student/profile/profile-data.service';
import {
  ProfileCardData,
  ProfileFieldsNames,
  Student
} from 'src/app/shared/components/profile-card/profile-card.model';
import { TestArchiveRecord } from 'src/app/student/profile/student-profile-test-archive/student-profile-test-archive.component';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { Role } from 'src/app/shared/role';
import { UserService } from 'src/app/shared/user.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { fadeAnimation } from '../../shared/animations/fade.animation';
import { ISubject } from 'src/app/shared/components/choose-subject/choose-subject.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'curr-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  animations: [fadeAnimation]
})
export class ProfileComponent implements OnInit, OnDestroy {
  planOptions = [
    // { value: 'PLUS', label: 'Exam Boost PLUS' },
    { value: 'STANDARD', label: 'Exam Boost' }
  ];
  selectedSubjects: ISubject[] = [];

  referralIcone = 'referral-data';
  copyIcone = 'copy';
  profileCardData: ProfileCardData;
  softQEdit = false;
  student: Student;
  softQuestion: StudentSoftQuestions;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  note =
    'Some note here. Some note here. Some note here. Some note here. Some note here. Some note here. Some note here.';
  testArchive: TestArchiveRecord[] = [];
  private subscription = new Subscription();
  tableData: any[] = [];
  userRole = Role;
  studId;
  profileData;
  currUser;
  userProfile: any;
  userReferralInfo$: Observable<any>;
  cancelButtonStyle = { color: '#006878', 'text-decoration': 'underline' };
  currentDate = new Date();
  LimitationDate = new Date(2021, 0, 7);
  isLimitDatePassed: boolean = false;
  userId: number;
  origin: string = '';

  constructor(
    private studentProfileService: StudentProfileService,
    private userService: UserService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private httpClient: ApplicationHttpClient,
    private profileDataService: ProfileDataService,
    private clipboard: Clipboard,
    private router: Router,
    private toastr: ToastrService,
  ) { }

  displayedColumns: string[] = ['subjectName', 'plan', 'subscriptionStatus', 'amount', 'lastPaymentStatus'];
  dataSource: any[];
  btnStyle = { padding: '10px 16px' };

  ngOnInit(): void {
    this.origin = location.origin;
    this.isLimitDatePassed = this.currentDate.getTime() > this.LimitationDate.getTime();
    this.profileData = this.route.snapshot.data.profileData;
    this.userService.activeUser$.next(this.profileData);

    this.subscription.add(
      this.userService.getCurrentUser().subscribe(resp => {
        this.currUser = resp;
      })
    );
    this.subscription.add(
      this.authService.getCurrentUser().subscribe(resp => {
        this.userProfile = resp;
      })
    );
    this.userReferralInfo$ = this.userService.getStudentReferralWidgetInfo().pipe(map((res: any) => res.data));
    this.subscription.add(
      this.userService.getSubscriptioninfo().subscribe(res => {
        this.dataSource = res;
      })
    );
    this.subscription.add(
      this.route.params.pipe(take(1)).subscribe(res => {
        this.studId = res?.studentId || 2020;
        this.subscription.add(
          this.studentProfileService.getStudentGrades(this.studId).subscribe(resp => {
            this.tableData = resp;
          })
        );
        this.subscription.add(
          this.studentProfileService.getStudentTests(this.studId).subscribe(resp => {
            this.testArchive = resp;
          })
        );
      }));

    this.studentProfileService.getStudentProfile('studentId1').subscribe({
      next: (studentProfileData: Student) => {
        this.listenOnUserProfileChanges();
      }
    });

    this.studentProfileService.getStudentSoftQ('studentId1').subscribe((softQ: StudentSoftQuestions) => {
      this.softQuestion = softQ;
    });
  }

  choosePlan(value: string, id: number): void {
    const item = {
      subjectId: id,
      paymentPlanType: value
    };
    const includesItemIdx = this.selectedSubjects.findIndex((elem: any) => elem.subjectId === id);

    if (~includesItemIdx) {
      item.paymentPlanType === 'Choose'
        ? this.selectedSubjects.splice(includesItemIdx, 1)
        : this.selectedSubjects[includesItemIdx].paymentPlanType = value;
    } else {
      this.selectedSubjects.push(item);
    }
    this.selectedSubjects.filter(t => t.paymentPlanType.includes('PLUS'))
    console.log(this.selectedSubjects)
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  updateUserProfile(updatedStudentData: any) {
    this.studentProfileService.updateStudent(updatedStudentData).subscribe({
      next: (data: any) => { }
    });
  }

  toggleEditSoftQ(value?: boolean) {
    this.softQEdit = !this.softQEdit;
  }

  removeChips(chipsList: string[], chip: string) {
    const index = chipsList.indexOf(chip);

    if (index >= 0) {
      this.userProfile.hobbies.splice(index, 1);
    }
  }

  addChips(event: any, chispList: string[]) {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.userProfile.hobbies.push(value.trim());
    }

    if (input) {
      input.value = '';
    }
  }

  saveHobbies() {
    this.httpClient.put(`/users/students`, this.userProfile).subscribe(resp => {
      this.softQEdit = false;
    });
  }

  copyLink(url: string): void {
    this.clipboard.copy(`${ this.origin }/sign-up/${ url }`);
    this.toastr.success('Link copied!');
  }

  cancelChanges() {
    this.authService.getCurrentUser().subscribe(resp => {
      this.userProfile = resp;
      this.softQEdit = false;
    });
  }

  private listenOnUserProfileChanges() {
    this.subscription.add(
      this.profileDataService.UserProfile.subscribe({
        next: (student: Student) => {
          this.profileCardData = {
            formFieldsToDisplay: [
              { label: 'Email', name: ProfileFieldsNames.EMAIL, verify: { valid: student.email.verify } },
              { label: 'Phone', name: ProfileFieldsNames.PHONE, verify: { valid: student.phone.verify } },
              { label: 'Personal ID', name: ProfileFieldsNames.PERSONAL_ID }
            ],
            userData: student
          };
          this.student = student;
        }
      })
    );
  }

  cancelSubscription(id: number) {
    this.subscription.add(
      this.userService.cancelSubscription(id).subscribe(() => {
        const filteredData = this.dataSource.filter(item => item.id == id);
        filteredData[0].subscriptionStatus = 'CANCELED';
    }));
  }

  refundPayment() {
    this.subscription.add(
      this.userService.RefundPaymentSubscription().subscribe(_ => {
      }));
  }

  goToAddSubjectsPage(): void {
    this.router.navigateByUrl('/add-subjects');
  }

  getFormatedData(date: number) {
    return new Date(date)
  }
}
